import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { PAGE_PATH } from '../constant/pages'
import { useGetTokenAndUserInfo } from './useGetTokenAndUserInfo'
import ErrorPage from '../layouts/ErrorPage'
import TermAndPrivacy from '../pages/TermAndPrivacy'

export function Routes() {
  useGetTokenAndUserInfo()

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={`/${PAGE_PATH.HOME}`} />
      </Route>

      {/* auth route */}
      <Route
        exact
        path={`/${PAGE_PATH.LOGIN}`}
        component={React.lazy(() => import('../pages/Login'))}
      />

      <Route
        exact
        path={`/${PAGE_PATH.SIGN_UP}`}
        component={React.lazy(() => import('../pages/SignUp'))}
      />

      <Route
        exact
        path={`/${PAGE_PATH.FORGET_PASSWORD}`}
        component={React.lazy(() => import('../pages/ForgetPassword'))}
      />

      <Route
        exact
        path={`/${PAGE_PATH.LOGOUT}`}
        component={React.lazy(() => import('../pages/Logout'))}
      />

      {/* E-book store route */}
      <Route
        exact
        path={`/${PAGE_PATH.HOME}`}
        component={React.lazy(() => import('../pages/EBookStore'))}
      />
      <Route
        exact
        path={`/${PAGE_PATH.LIST_BOOK}`}
        component={React.lazy(() => import('../pages/ListBook'))}
      />
      <Route
        exact
        path={`/${PAGE_PATH.WISH_LIST}`}
        component={React.lazy(() => import('../pages/WishList'))}
      />
      <Route
        exact
        path={`/${PAGE_PATH.ORDER_HISTORY}`}
        component={React.lazy(() => import('../pages/OrderHistory'))}
      />
      <Route
        exact
        path={`/${PAGE_PATH.BOOK_DETAIL}/:bookId`}
        component={React.lazy(() => import('../pages/BookDetail'))}
      />

      {/* Point History route */}
      <Route
        exact
        path={`/${PAGE_PATH.POINT_HISTORY}`}
        component={React.lazy(() => import('../pages/PointHistory'))}
      />

      {/* My E-book route */}
      <Route
        exact
        path={`/${PAGE_PATH.MY_E_BOOK}`}
        component={React.lazy(() => import('../pages/MyEBook'))}
      />

      <Route
        exact
        path={`/${PAGE_PATH.EDIT_PDF}/`}
        component={React.lazy(() => import('../pages/EditPdf'))}
      />

      {/* profile route */}
      <Route
        exact
        path={`/${PAGE_PATH.PROFILE}`}
        component={React.lazy(() => import('../pages/Profile'))}
      />

      <Route
        exact
        path={`/${PAGE_PATH.PURCHASE_POINT}`}
        component={React.lazy(() => import('../pages/PointPurchase'))}
      />

      <Route exact path={'/payment/ebook=:id/usertoken=:token'}></Route>

      {/* TermAndPrivacy */}
      <Route exact path={`/${PAGE_PATH.PURCHASE_TERM}`}>
        <TermAndPrivacy type={'PurchaseTerm'} />
      </Route>

      <Route exact path={`/${PAGE_PATH.TERM_AND_PRIVACY}`}>
        <TermAndPrivacy type={'PrivacyStatement'} />
      </Route>

      <Route path="*" component={ErrorPage} />
    </Switch>
  )
}
