import React, { useState } from 'react'
import { PopupHelper } from '../../../utils/helpers/PopupHelper'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { verifySerialCodeEbookApi } from '../../../services/order'
import { ErrorMessage, Formik } from 'formik'
import { SerialCodeData } from '../../../model'
import { serialCodeSchema } from '../../../model/ValidationSchema'
import { PURCHASE_BOOK_MESSAGE } from '../../../lang/message'
import { ErrorCode } from '../../../services/api/types'
import { makeMarkedSerialCode } from '../../../utils'

type VerifyCodeContentProps = {
  closeModal: () => void
  bookId: number | string
  setBookPrice: (arg0: number) => void
  changeContent: () => void
  textCode: string
  setTextCode: (arg0: string) => void
}

const initialValues: SerialCodeData = {
  serial_code: '',
}

const VerifyCodeContent: React.FC<VerifyCodeContentProps> = ({
  closeModal,
  bookId,
  setBookPrice,
  changeContent,
  setTextCode,
}) => {
  const history = useHistory()
  const { token, userInfo } = useSelector((state: any) => state.userInfo)
  const { point } = userInfo

  const [textError, setTextError] = useState<string>('')

  const closeModalAndClearData = () => {
    setTextError('')
    closeModal()
  }

  const openModalNotEnoughPoint = async () => {
    closeModalAndClearData()
    await new Promise((resolve) => setTimeout(resolve, 300))
    PopupHelper.showPopup({
      title: PURCHASE_BOOK_MESSAGE.NOT_ENOUGH_POINT_TITLE,
      message: PURCHASE_BOOK_MESSAGE.NOT_ENOUGH_POINT_MESSAGE,
      buttonTitle: PURCHASE_BOOK_MESSAGE.NOT_ENOUGH_POINT_BUTTON,
      visibleIcon: true,
      buttonCallback: () => {
        setTimeout(() => {
          history.push(`/payment/ebook=${bookId}/usertoken=${token}`)
        }, 300)
      },
    })
  }

  const handleSendCode = async ({ serial_code }: SerialCodeData) => {
    try {
      const dataSubmit = {
        ebook: bookId,
        serial_code: serial_code,
      }
      const res = await verifySerialCodeEbookApi(dataSubmit)
      if (res?.data) {
        const bookPriceRes = res.data.point ?? 0
        if (bookPriceRes > point) {
          openModalNotEnoughPoint()
        } else {
          setBookPrice(bookPriceRes)
          setTextCode(serial_code)
          changeContent()
        }
      }
    } catch (error: any) {
      console.log('error', error)
      handleErrorCode(error)
    }
  }

  const handleErrorCode = (value: any) => {
    const errorObj = { ...value }
    const { error_code } = errorObj?.response?.data
    if (error_code === ErrorCode.NOT_ENOUGH_POINT) {
      // Not enough point
      openModalNotEnoughPoint()
      return
    }
    if (error_code === ErrorCode.USED_SERIAL_CODE) {
      setTextError(PURCHASE_BOOK_MESSAGE.USED_SERIAL_CODE)
      return
    }
    setTextError(PURCHASE_BOOK_MESSAGE.WRONG_SERIAL_CODE)
  }

  return (
    <div className="sign-in-from bg-white rounded border shadow-container">
      <div className="m-0 text-center text-primary mt-n3">
        <i className="ri-error-warning-fill font-size-120 m-0 p-0"></i>
      </div>
      <h3 className="mb-0 text-center" style={{ color: '#969B94' }}>
        <strong>교재 인증 코드를 입력해주세요</strong>
      </h3>
      <div className="p-4">
        <h5 className="text-center mt-3" style={{ color: '#969B94' }}>
          <p className="m-0 p-0">교재 인증 코드가 있어야 E북을 구매할 수 있습니다.</p>
          <p className="m-0 p-0">구매하신 지류 교재에서 인증코드를 확인하세요.</p>
        </h5>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={serialCodeSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSendCode(values)
          setSubmitting(false)
        }}
      >
        {({ values, errors, handleBlur, handleSubmit, isSubmitting, setValues }) => (
          <form onSubmit={handleSubmit}>
            <div
              className={`mt-4 border p-3 rounded ${
                (textError || errors.serial_code) && 'border-danger'
              }`}
            >
              <input
                type="text"
                name="serial_code"
                placeholder="인증코드 10자리를 입력해주세요."
                value={makeMarkedSerialCode(values.serial_code)}
                onChange={(event) => {
                  if (textError) {
                    setTextError('')
                  }
                  let text = event?.target?.value
                  if (typeof text !== 'string') {
                    setValues((prevState) => {
                      return { ...prevState, serial_code: '' }
                    })
                    return
                  }
                  text = text.replace(/[^a-zA-Z0-9]/g, '')
                  text = text.toUpperCase()
                  text = text.slice(0, 10)
                  setValues((prevState) => {
                    return { ...prevState, serial_code: text }
                  })
                }}
                onBlur={handleBlur}
                className="input-style"
              />
            </div>
            {textError ? (
              <p className="text-center text-danger m-0 p-0 text-sm w-100">{textError}</p>
            ) : (
              <ErrorMessage
                name={'serial_code'}
                render={(msg) => (
                  <p className="text-center text-danger m-0 p-0 text-sm w-100">{msg}</p>
                )}
              />
            )}
            <div className="mt-5 d-flex justify-content-around">
              <div className="col-6">
                <button
                  className="btn btn-primary btn-block btn-lg mt-1"
                  type="submit"
                  disabled={isSubmitting || !!errors?.serial_code || textError !== ''}
                >
                  입력 확인
                </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-secondary btn-block bg-secondary btn-lg  mt-1"
                  onClick={() => {
                    errors.serial_code = undefined
                    closeModalAndClearData()
                  }}
                  disabled={isSubmitting}
                >
                  취소
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default VerifyCodeContent
