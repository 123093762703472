import { useEffect } from 'react'
import { getTokenFromLocalStorage } from '../utils'
import { useDispatch } from 'react-redux'
import { TYPES } from '../redux/types'

export function useGetTokenAndUserInfo() {
  const dispatch = useDispatch()
  const screenWidth = window.innerWidth

  const token = getTokenFromLocalStorage()

  useEffect(() => {
    if (token) {
      dispatch({ type: TYPES.SET_ACCESS_TOKEN, payload: { token: token } })
      dispatch({ type: TYPES.SAGA_GET_PROFILE })
    } else {
      dispatch({ type: TYPES.CLEAR_ALL_USER_INFO })
    }

    if (screenWidth > 1300) {
      dispatch({ type: TYPES.OPEN_SIDEBAR })
    } else {
      dispatch({ type: TYPES.CLOSE_SIDEBAR })
    }
  }, [])
}
