import { ForgetPasswordNameAndEmailData, LoginData, ResetPassData, SignUpData } from '../model'
import { createUrl, patch, post } from './api/api'

const authPrefix = '/auth/v1/'

export function loginApi(data: LoginData) {
  return post(createUrl(authPrefix, 'login'), data)
}

export function logoutApi() {
  return post(createUrl(authPrefix, 'logout'))
}

export function signUpApi(data: Omit<SignUpData, 'confirmPassword'>) {
  return post(createUrl(authPrefix, 'register'), data)
}

export function resetPasswordApi(data: ResetPassData) {
  return post(createUrl(authPrefix, 'reset-pass'), data)
}

export function sendResetPasswordApi(data: ForgetPasswordNameAndEmailData) {
  return post(createUrl(authPrefix, 'send-reset-pass'), data)
}

export function changePasswordApi(data: { old_password: string; new_password: string }) {
  return patch(createUrl(authPrefix, 'change-password'), data)
}

export function verifyCodeApi(data: { email: string; verify_code: string }) {
  return post(createUrl(authPrefix, 'verify-code'), data)
}
