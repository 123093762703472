export const DATE_FORMAT = {
  FULL_INFO: 'YYYY-MM-DD HH:mm:ss',
  FULL_INFO_NO_SECOND: 'YYYY-MM-DD HH:mm',
  ONLY_DATE: 'YYYY-MM-DD',
  ONLY_DATE_DOT: 'YYYY.MM.DD',
  ONLY_DATE_SLASH: 'YYYY/MM/DD',
  ONLY_TIME: 'HH:mm:ss',
  ONLY_TIME_DOT: 'HH.mm.ss',
}

export const PERMISSION = {
  PUBLIC: 'public',
  GENERAL_USER: 'general_user',
  ADMIN: 'admin',
}

export enum CertType {
  G_SCHOOL_CERT = 1,
  SERIAL_CERT = 2,
  NOT_CERT = 3,
}

export enum ParamScreen {
  page = 'page',
  category = 'category',
  search = 'search',
  tab = 'tab',
}

export enum PurchaseType {
  PURCHASE_POINT = 1,
  PURCHASE_EBOOK = 2,
  REFUND_POINT = 3,
}
