import React from 'react'
import { useHistory } from 'react-router-dom'
import { PAGE_PATH } from '../../constant/pages'

const ErrorPage: React.FC = () => {
  const history = useHistory()

  return (
    <div className="wrapper">
      <div className="container p-0">
        <div className="row no-gutters height-self-center">
          <div className="col-sm-12 text-center align-self-center">
            <div className="iq-error position-relative">
              <img src="images/error/404.png" className="img-fluid iq-error-img" alt="" />
              <h2 className="mb-0 mt-4">Oops! This Page is Not Found.</h2>
              <p>The requested page dose not exist.</p>
              <a
                className="btn btn-primary mt-3"
                onClick={() => {
                  history.replace(`/${PAGE_PATH.HOME}`)
                }}
              >
                <i className="ri-home-4-line"></i>Back to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
