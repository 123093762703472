import { createUrl, post } from './api/api'

const ebookOrderPrefix = '/ebook-order/v1/'

export async function postOrderEbook(data: { ebook: any; serial_code: string }) {
  return post(createUrl(ebookOrderPrefix), data)
}

export async function verifySerialCodeEbookApi(data: {
  ebook: number | string
  serial_code: string
}) {
  return post(createUrl(ebookOrderPrefix, 'verify-serial-code'), data)
}
