import React, { useRef, useState } from 'react'
import { convertNumberToMoneyType } from '../../../utils'
import { useDispatch, useSelector } from 'react-redux'
import TermAndPrivacyPopUp, { TermAndPrivacyPopUpHandle } from '../../TermAndPrivacyPopUp'
import { postOrderEbook } from '../../../services/order'
import { PopupHelper } from '../../../utils/helpers/PopupHelper'
import { useHistory } from 'react-router-dom'
import { PAGE_PATH } from '../../../constant/pages'
import { PURCHASE_BOOK_MESSAGE } from '../../../lang/message'
import { TEXT_COLOR } from '../../../constant/colors'
import { TYPES } from '../../../redux/types'

type ReceiptContentProps = {
  bookPrice: number
  closeModal: () => void
  bookId: number | string
  textCode: string
}

const ReceiptContent: React.FC<ReceiptContentProps> = ({
  bookPrice,
  closeModal,
  bookId,
  textCode,
}) => {
  const popupTermAndPrivacy = useRef<TermAndPrivacyPopUpHandle | null>(null)
  const dispatch = useDispatch()
  const history = useHistory()

  const { userInfo } = useSelector((state: any) => state.userInfo)
  const { point } = userInfo

  const [isCheckPrivacy, setIsCheckPrivacy] = useState<boolean>(false)

  const handleBuyEbook = async () => {
    try {
      const dataSubmit = {
        ebook: bookId,
        serial_code: textCode,
      }
      await postOrderEbook(dataSubmit)
      dispatch({ type: TYPES.SAGA_GET_PROFILE })
      PopupHelper.showPopup({
        visibleIcon: true,
        title: PURCHASE_BOOK_MESSAGE.PURCHASE_SUCCESS_TITLE,
        message: PURCHASE_BOOK_MESSAGE.PURCHASE_SUCCESS_MESSAGE,
        buttonTitle: PURCHASE_BOOK_MESSAGE.PURCHASE_SUCCESS_BUTTON_CHECK_POINT_HISTORY,
        buttonCallback: () => {
          history.push(`/${PAGE_PATH.POINT_HISTORY}`)
        },
        buttonTitleSecond: PURCHASE_BOOK_MESSAGE.PURCHASE_SUCCESS_BUTTON_CLOSE,
      })
    } catch (error: any) {
      PopupHelper.showPopup({
        visibleIcon: true,
        title: PURCHASE_BOOK_MESSAGE.PURCHASE_FAIL_TITLE,
        message: PURCHASE_BOOK_MESSAGE.PURCHASE_FAIL_MESSAGE,
      })
    } finally {
      closeModal()
    }
  }

  return (
    <>
      <TermAndPrivacyPopUp
        ref={popupTermAndPrivacy}
        type="PrivacyStatement"
        onClickButton={() => {}}
      />
      <div className="sign-in-from bg-primary rounded">
        <h3 className="mb-0 text-center text-white mt-4">
          <strong>구매하기</strong>
        </h3>
        <p className="text-center text-white mt-3">
          이전에 충전했던 포인트를 사용하여 구매합니다.{' '}
        </p>
        <table className="w-100 mt-5">
          <thead>
            <tr className="text-primary font-weight-bold">
              <td className="align-middle text-center bg-white rounded-left">보유 포인트</td>
              <td className="align-middle text-center bg-white">사용 포인트</td>
              <td className="align-middle text-center bg-white rounded-right">잔여 포인트</td>
            </tr>
          </thead>
          <tbody className="text-white border-bottom border-white">
            <tr>
              <td className="align-middle text-center py-4">{`${convertNumberToMoneyType(
                point
              )}POINT`}</td>
              <td className="align-middle text-center py-4">
                {`${convertNumberToMoneyType(bookPrice)}POINT`}
              </td>
              <td className="align-middle text-center py-4">
                {`${convertNumberToMoneyType(point - bookPrice)}POINT`}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-4">
          <p className="text-right text-white mb-0 font-size-14">
            *구매 내역은 주문내역에서 확인할 수 있습니다.{' '}
          </p>
          <div className="d-inline-block w-100 d-flex justify-content-end">
            <div className="custom-control custom-checkbox d-inline-block mt-1 font-size-14 text-white">
              <input
                type="checkbox"
                className="custom-control-input"
                id="exampleInput-checkbox-receipt"
                onChange={(e) => {
                  setIsCheckPrivacy(e.target.checked)
                }}
              />
              <label
                className="custom-control-label text-right text-white"
                htmlFor="exampleInput-checkbox-receipt"
              >
                <a
                  className="text-white"
                  onClick={() => {
                    if (popupTermAndPrivacy) {
                      popupTermAndPrivacy.current?.openModal()
                    }
                  }}
                >
                  <u>이용약관</u>
                </a>
                에 동의합니다. <span style={{ color: TEXT_COLOR.TEXT_NOTE }}>*필수</span>
              </label>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <button
            className="btn btn-white d-block w-100"
            onClick={handleBuyEbook}
            disabled={!isCheckPrivacy}
          >
            구매하기
          </button>
          <button className="btn d-block w-100 mt-1 btn-primary" onClick={closeModal}>
            취소하기
          </button>
        </div>
      </div>
    </>
  )
}

export default ReceiptContent
