import jQuery from "jquery";
/*
Template: Booksto - Responsive Bootstrap 4 Admin Dashboard Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template.
*/

/*----------------------------------------------
Index Of Script
------------------------------------------------

:: Tooltip
:: Sidebar Widget
:: Magnific Popup
:: Ripple Effect
:: Page Loader
:: Owl Carousel
:: Select input
:: Search input
:: Scrollbar
:: Counter
:: slick
:: Progress Bar
:: Page Menu
:: Page Loader
:: Page Menu
:: Wow Animation
:: Mail Inbox
:: Chat
:: Todo
:: Form Validation
:: Sidebar Widget
:: Flatpicker
:: Checkout
:: Datatables
:: Button

------------------------------------------------
Index Of Script
----------------------------------------------*/

(function (jQuery) {
  jQuery(document).ready(function () {
    /*---------------------------------------------------------------------
        Tooltip
        -----------------------------------------------------------------------*/
    // jQuery('[data-toggle="popover"]').popover()
    // jQuery('[data-toggle="tooltip"]').tooltip()

    /*---------------------------------------------------------------------
        Sidebar Widget
        -----------------------------------------------------------------------*/
    // function checkClass(ele, type, className) {
    //   switch (type) {
    //     case 'addClass':
    //       if (!ele.hasClass(className)) {
    //         ele.addClass(className)
    //       }
    //       break
    //     case 'removeClass':
    //       if (ele.hasClass(className)) {
    //         ele.removeClass(className)
    //       }
    //       break
    //     case 'toggleClass':
    //       ele.toggleClass(className)
    //       break
    //   }
    // }

    /*---------------------------------------------------------------------
        Magnific Popup
        -----------------------------------------------------------------------*/
    // jQuery('.popup-gallery').magnificPopup({
    //   delegate: 'a.popup-img',
    //   type: 'image',
    //   tLoading: 'Loading image #%curr%...',
    //   mainClass: 'mfp-img-mobile',
    //   gallery: {
    //     enabled: true,
    //     navigateByImgClick: true,
    //     preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
    //   },
    //   image: {
    //     tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    //     titleSrc: function (item) {
    //       return item.el.attr('title') + '<small>by Marsel Van Oosten</small>'
    //     },
    //   },
    // })
    // jQuery('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    //   disableOn: 700,
    //   type: 'iframe',
    //   mainClass: 'mfp-fade',
    //   removalDelay: 160,
    //   preloader: false,
    //   fixedContentPos: false,
    // })

    /*---------------------------------------------------------------------
        Ripple Effect
        -----------------------------------------------------------------------*/
    // jQuery(document).on('click', '.iq-waves-effect', function (e) {
    //   // Remove any old one
    //   jQuery('.ripple').remove()
    //   // Setup
    //   let posX = jQuery(this).offset().left,
    //     posY = jQuery(this).offset().top,
    //     buttonWidth = jQuery(this).width(),
    //     buttonHeight = jQuery(this).height()

    //   // Add the element
    //   jQuery(this).prepend("<span class='ripple'></span>")

    //   // Make it round!
    //   if (buttonWidth >= buttonHeight) {
    //     buttonHeight = buttonWidth
    //   } else {
    //     buttonWidth = buttonHeight
    //   }

    //   // Get the center of the element
    //   let x = e.pageX - posX - buttonWidth / 2
    //   let y = e.pageY - posY - buttonHeight / 2

    //   // Add the ripples CSS and start the animation
    //   jQuery('.ripple')
    //     .css({
    //       width: buttonWidth,
    //       height: buttonHeight,
    //       top: y + 'px',
    //       left: x + 'px',
    //     })
    //     .addClass('rippleEffect')
    // })

    /*---------------------------------------------------------------------
        Sidebar Widget
        -----------------------------------------------------------------------*/

    // jQuery(document).on('click', '.iq-menu > li > a', function () {
    //   jQuery('.iq-menu > li > a').parent().removeClass('active')
    //   jQuery(this).parent().addClass('active')
    // })

    /*---------------------------------------------------------------------
        Page faq
        -----------------------------------------------------------------------*/
    // jQuery('.iq-accordion .iq-accordion-block .accordion-details').hide()
    // jQuery('.iq-accordion .iq-accordion-block:first')
    //   .addClass('accordion-active')
    //   .children()
    //   .slideDown('slow')
    // jQuery(document).on('click', '.iq-accordion .iq-accordion-block', function () {
    //   if (jQuery(this).children('div.accordion-details ').is(':hidden')) {
    //     jQuery('.iq-accordion .iq-accordion-block')
    //       .removeClass('accordion-active')
    //       .children('div.accordion-details ')
    //       .slideUp('slow')
    //     jQuery(this)
    //       .toggleClass('accordion-active')
    //       .children('div.accordion-details ')
    //       .slideDown('slow')
    //   }
    // })

    /*---------------------------------------------------------------------
        Page Loader
        -----------------------------------------------------------------------*/
    // jQuery('#load').fadeOut()
    // jQuery('#loading').delay().fadeOut('')

    /*---------------------------------------------------------------------
       Owl Carousel
       -----------------------------------------------------------------------*/
    // jQuery('.owl-carousel').each(function () {
    //   let jQuerycarousel = jQuery(this)
    //   jQuerycarousel.owlCarousel({
    //     items: jQuerycarousel.data('items'),
    //     loop: jQuerycarousel.data('loop'),
    //     margin: jQuerycarousel.data('margin'),
    //     nav: jQuerycarousel.data('nav'),
    //     dots: jQuerycarousel.data('dots'),
    //     autoplay: jQuerycarousel.data('autoplay'),
    //     autoplayTimeout: jQuerycarousel.data('autoplay-timeout'),
    //     navText: [
    //       "<i class='fa fa-angle-left fa-2x'></i>",
    //       "<i class='fa fa-angle-right fa-2x'></i>",
    //     ],
    //     responsiveClass: true,
    //     responsive: {
    //       // breakpoint from 0 up
    //       0: {
    //         items: jQuerycarousel.data('items-mobile-sm'),
    //         nav: false,
    //         dots: true,
    //       },
    //       // breakpoint from 480 up
    //       480: {
    //         items: jQuerycarousel.data('items-mobile'),
    //         nav: false,
    //         dots: true,
    //       },
    //       // breakpoint from 786 up
    //       786: {
    //         items: jQuerycarousel.data('items-tab'),
    //       },
    //       // breakpoint from 1023 up
    //       1023: {
    //         items: jQuerycarousel.data('items-laptop'),
    //       },
    //       1199: {
    //         items: jQuerycarousel.data('items'),
    //       },
    //     },
    //   })
    // })

    /*---------------------------------------------------------------------
        Select input
        -----------------------------------------------------------------------*/
    // jQuery('.select2jsMultiSelect').select2({
    //   tags: true,
    // })

    /*---------------------------------------------------------------------
        Search input
        -----------------------------------------------------------------------*/
    // jQuery(document).on('click', function (e) {
    //   let myTargetElement = e.target
    //   let selector, mainElement
    //   if (
    //     jQuery(myTargetElement).hasClass('search-toggle') ||
    //     jQuery(myTargetElement).parent().hasClass('search-toggle') ||
    //     jQuery(myTargetElement).parent().parent().hasClass('search-toggle')
    //   ) {
    //     if (jQuery(myTargetElement).hasClass('search-toggle')) {
    //       selector = jQuery(myTargetElement).parent()
    //       mainElement = jQuery(myTargetElement)
    //     } else if (jQuery(myTargetElement).parent().hasClass('search-toggle')) {
    //       selector = jQuery(myTargetElement).parent().parent()
    //       mainElement = jQuery(myTargetElement).parent()
    //     } else if (jQuery(myTargetElement).parent().parent().hasClass('search-toggle')) {
    //       selector = jQuery(myTargetElement).parent().parent().parent()
    //       mainElement = jQuery(myTargetElement).parent().parent()
    //     }
    //     if (!mainElement.hasClass('active') && jQuery('.navbar-list li').find('.active')) {
    //       jQuery('.navbar-list li').removeClass('iq-show')
    //       jQuery('.navbar-list li .search-toggle').removeClass('active')
    //     }

    //     selector.toggleClass('iq-show')
    //     mainElement.toggleClass('active')

    //     e.preventDefault()
    //   } else if (jQuery(myTargetElement).is('.search-input')) {
    //   } else {
    //     jQuery('.navbar-list li').removeClass('iq-show')
    //     jQuery('.navbar-list li .search-toggle').removeClass('active')
    //   }
    // })

    /*---------------------------------------------------------------------
        Scrollbar
        -----------------------------------------------------------------------*/
    // let Scrollbar = window.Scrollbar
    // if (jQuery('#sidebar-scrollbar').length) {
    //   Scrollbar.init(document.querySelector('#sidebar-scrollbar'), options)
    // }
    // let Scrollbar1 = window.Scrollbar
    // if (jQuery('#right-sidebar-scrollbar').length) {
    //   Scrollbar1.init(document.querySelector('#right-sidebar-scrollbar'), options)
    // }

    /*---------------------------------------------------------------------
        Counter
        -----------------------------------------------------------------------*/
    // jQuery('.counter').counterUp({
    //   delay: 10,
    //   time: 1000,
    // })

    /*---------------------------------------------------------------------
        slick
        -----------------------------------------------------------------------*/
    // jQuery('.slick-slider').slick({
    //   centerMode: true,
    //   centerPadding: '60px',
    //   slidesToShow: 9,
    //   slidesToScroll: 1,
    //   focusOnSelect: true,
    //   responsive: [
    //     {
    //       breakpoint: 992,
    //       settings: {
    //         arrows: false,
    //         centerMode: true,
    //         centerPadding: '30',
    //         slidesToShow: 3,
    //       },
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         arrows: false,
    //         centerMode: true,
    //         centerPadding: '15',
    //         slidesToShow: 1,
    //       },
    //     },
    //   ],
    //   nextArrow: '<a href="#" class="ri-arrow-left-s-line left"></a>',
    //   prevArrow: '<a href="#" class="ri-arrow-right-s-line right"></a>',
    // })

    // $('#trendy-slider').slick({
    //   dots: false,
    //   arrows: false,
    //   infinite: true,
    //   speed: 300,
    //   centerMode: false,
    //   autoplay: true,
    //   slidesToShow: 4,
    //   slidesToScroll: 1,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         infinite: true,
    //       },
    //     },
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 576,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //       },
    //     },
    //   ],
    // })

    // $('#description-slider').slick({
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   arrows: false,
    //   fade: true,
    //   asNavFor: '#description-slider-nav',
    // })

    // $('#description-slider-nav').slick({
    //   slidesToShow: 3,
    //   slidesToScroll: 1,
    //   asNavFor: '#description-slider',
    //   dots: false,
    //   arrows: false,
    //   infinite: true,
    //   vertical: true,
    //   centerMode: false,
    //   focusOnSelect: true,
    // })

    /*---------------------------------------------------------------------
        Progress Bar
        -----------------------------------------------------------------------*/
    // jQuery('.iq-progress-bar > span').each(function () {
    //   let progressBar = jQuery(this)
    //   let width = jQuery(this).data('percent')
    //   progressBar.css({
    //     transition: 'width 2s',
    //   })

    //   setTimeout(function () {
    //     progressBar.appear(function () {
    //       progressBar.css('width', width + '%')
    //     })
    //   }, 100)
    // })

    /*---------------------------------------------------------------------
        Page Menu
        -----------------------------------------------------------------------*/
    // jQuery(document).on('click', '.wrapper-menu', function () {
    //   jQuery(this).toggleClass('open')
    // })

    // jQuery(document).on('click', '.wrapper-menu', function () {
    //   jQuery('body').toggleClass('sidebar-main')
    // })

    /*---------------------------------------------------------------------
        Wow Animation
        -----------------------------------------------------------------------*/
    // let wow = new WOW({
    //   boxClass: 'wow',
    //   animateClass: 'animated',
    //   offset: 0,
    //   mobile: false,
    //   live: true,
    // })
    // wow.init()

    /*---------------------------------------------------------------------
        Form Validation
        -----------------------------------------------------------------------*/

    // Example starter JavaScript for disabling form submissions if there are invalid fields
    // window.addEventListener(
    //   'load',
    //   function () {
    //     // Fetch all the forms we want to apply custom Bootstrap validation styles to
    //     var forms = document.getElementsByClassName('needs-validation')
    //     // Loop over them and prevent submission
    //     var validation = Array.prototype.filter.call(forms, function (form) {
    //       form.addEventListener(
    //         'submit',
    //         function (event) {
    //           if (form.checkValidity() === false) {
    //             event.preventDefault()
    //             event.stopPropagation()
    //           }
    //           form.classList.add('was-validated')
    //         },
    //         false
    //       )
    //     })
    //   },
    //   false
    // )

    /*---------------------------------------------------------------------
        Sidebar Widget
        -----------------------------------------------------------------------*/
    // jQuery(document).ready(function () {
    //   jQuery().on('click', '.todo-task-lists li', function () {
    //     if (jQuery(this).find('input:checkbox[name=todo-check]').is(':checked')) {
    //       jQuery(this).find('input:checkbox[name=todo-check]').attr('checked', false)
    //       jQuery(this).removeClass('active-task')
    //     } else {
    //       jQuery(this).find('input:checkbox[name=todo-check]').attr('checked', true)
    //       jQuery(this).addClass('active-task')
    //     }
    //   })
    // })

    /*---------------------------------------------------------------------
           Scroll up menu
        -----------------------------------------------------------------------*/
    // var position = $(window).scrollTop()
    // $(window).scroll(function () {
    //   var scroll = $(window).scrollTop()
    //   //  console.log(scroll);

    //   if (scroll < position) {
    //     $('.tab-menu-horizontal').addClass('menu-up')
    //     $('.tab-menu-horizontal').removeClass('menu-down')
    //   } else {
    //     $('.tab-menu-horizontal').addClass('menu-down')
    //     $('.tab-menu-horizontal').removeClass('menu-up')
    //   }
    //   if (scroll == 0) {
    //     $('.tab-menu-horizontal').removeClass('menu-up')
    //     $('.tab-menu-horizontal').removeClass('menu-down')
    //   }
    //   position = scroll
    // })

    /*---------------------------------------------------------------------
           checkout
        -----------------------------------------------------------------------*/

    // jQuery(document).ready(function () {
    //   jQuery('#place-order').click(function () {
    //     jQuery('#cart').removeClass('show')
    //     jQuery('#address').addClass('show')
    //   })
    //   jQuery('#deliver-address').click(function () {
    //     jQuery('#address').removeClass('show')
    //     jQuery('#payment').addClass('show')
    //   })
    // })

    /*---------------------------------------------------------------------
           Datatables
        -----------------------------------------------------------------------*/
    // if (jQuery('.data-tables').length) {
    //   $('.data-tables').DataTable()
    // }

    /*---------------------------------------------------------------------
        Button 
        -----------------------------------------------------------------------*/

    // jQuery('.qty-btn').on('click', function () {
    //   var id = jQuery(this).attr('id')

    //   var val = parseInt(jQuery('#quantity').val())

    //   if (id == 'btn-minus') {
    //     if (val != 0) {
    //       jQuery('#quantity').val(val - 1)
    //     } else {
    //       jQuery('#quantity').val(0)
    //     }
    //   } else {
    //     jQuery('#quantity').val(val + 1)
    //   }
    // })
  })
})(jQuery)
