export const PAGE_PATH = {
  //auth path
  LOGIN: 'login',
  SIGN_UP: 'sign_up',
  FORGET_PASSWORD: 'forget_password',
  LOGOUT: 'logout',

  //e book path
  HOME: 'home',
  LIST_BOOK: 'list_book',
  WISH_LIST: 'wish_list',
  ORDER_HISTORY: 'order_history',
  BOOK_DETAIL: 'book_detail',

  //point history
  POINT_HISTORY: 'point_history',

  //my ebook
  MY_E_BOOK: 'my_e_book',
  EDIT_PDF: 'edit_pdf',

  //profile
  PROFILE: 'profile',
  PURCHASE_POINT: 'purchase_point',

  //TermAndPrivacy
  TERM_AND_PRIVACY: 'term_and_privacy',
  PURCHASE_TERM: 'purchase_term',
}
