import { object, string, boolean, ref, mixed } from 'yup'
import {
  VALIDATION_MESSAGE,
  validateExactLength,
  validateMaxLength,
  validateMinLength,
} from '../../lang/validate'

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

export const loginSchema = object({
  email: string().required(VALIDATION_MESSAGE.REQUIRED),
  password: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(8, ({ min }) => validateMinLength(min)),
  isRememberPassword: boolean(),
})

export const signUpSchema = object({
  nickname: string().max(50, ({ max }) => validateMaxLength(max)),
  email: string().required(VALIDATION_MESSAGE.REQUIRED).email(VALIDATION_MESSAGE.INVALID_EMAIL),
  password: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(8, ({ min }) => validateMinLength(min)),
  confirmPassword: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(8, ({ min }) => validateMinLength(min))
    .oneOf([ref('password')], VALIDATION_MESSAGE.PASSWORD_NOT_MATCH),
})

export const forgetPasswordNameAndEmailSchema = object({
  nickname: string().max(50, ({ max }) => validateMaxLength(max)),
  email: string().required(VALIDATION_MESSAGE.REQUIRED).email(VALIDATION_MESSAGE.INVALID_EMAIL),
})

export const forgetPasswordValidationCodeSchema = object({
  verify_code: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .length(4, ({ length }) => validateExactLength(length)),
})

export const forgetPasswordChangeNewPasswordSchema = object({
  password: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(8, ({ min }) => validateMinLength(min)),
  confirmPassword: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(8, ({ min }) => validateMinLength(min))
    .oneOf([ref('password')], VALIDATION_MESSAGE.PASSWORD_NOT_MATCH),
})

export const userInfoSchema = object({
  avatar: mixed()
    .test('fileSize', VALIDATION_MESSAGE.FILE_TOO_BIG, (value: any) => {
      if (value === undefined || typeof value === 'string') {
        return true
      }
      return value && value.size <= 1024 * 1024
    })
    .test('format', VALIDATION_MESSAGE.IMAGE_ONLY, (value: any) => {
      if (value === undefined || typeof value === 'string') {
        return true
      }
      return value && SUPPORTED_FORMATS.includes(value.type)
    }),
  nickname: string().max(50, ({ max }) => validateMaxLength(max)),
  email: string().required(VALIDATION_MESSAGE.REQUIRED).email(VALIDATION_MESSAGE.INVALID_EMAIL),
})

export const changePasswordSchema = object({
  oldPassword: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(8, ({ min }) => validateMinLength(min)),
  password: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(8, ({ min }) => validateMinLength(min)),
  confirmPassword: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(8, ({ min }) => validateMinLength(min))
    .oneOf([ref('password')], VALIDATION_MESSAGE.PASSWORD_NOT_MATCH),
})

export const serialCodeSchema = object({
  serial_code: string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .length(10, ({ length }) => validateExactLength(length))
    .matches(/^[A-Z0-9]+$/, {
      message: VALIDATION_MESSAGE.ONLY_HAVE_LETTERS_NUMBERS,
    }),
})
