import { PopupHandle } from '../../components/PopUpCode'

export class PopUpCodeHelper {
  static popup: PopupHandle | null

  static setDropDown(popup: PopupHandle | null): void {
    if (popup) {
      this.popup = popup
    }
  }

  static showPopup(bookId: string | number, price: number, isSkipVerifyCode?: boolean): void {
    if (this.popup) {
      this.popup.openModal(bookId, price, isSkipVerifyCode)
    }
  }

  static closePopup(): void {
    if (this.popup) {
      this.popup.closeModal()
    }
  }
}
