import {
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  forwardRef,
  useEffect,
  useRef,
} from 'react'
import './index.css'
import TermAndPrivacy from '../../pages/TermAndPrivacy'

export type TermAndPrivacyPopUpHandle = {
  openModal: () => void
  closeModal: () => void
}

type TermAndPrivacyPopUpProps = {
  onClickButton?: () => void
  type: 'PrivacyStatement' | 'PurchaseTerm'
}

const TermAndPrivacyPopUp: ForwardRefRenderFunction<
  TermAndPrivacyPopUpHandle,
  TermAndPrivacyPopUpProps
> = ({ onClickButton, type }, ref) => {
  const divRef = useRef<HTMLDivElement | null>(null)

  const [visible, setVisible] = useState<boolean>(false)

  useImperativeHandle(
    ref,
    () => ({
      openModal: () => {
        setVisible(true)
      },
      closeModal: () => {
        setVisible(false)
      },
    }),
    []
  )

  useEffect(() => {
    if (visible && divRef.current) {
      divRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [visible])

  if (!visible) {
    return null
  }

  return (
    <div className="term-and-privacy-background-popup">
      <div
        className="d-flex justify-content-center align-items-center w-100 h-100"
        style={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}
      >
        <div
          className="shadow round bg-white py-4 px-0 term-and-privacy-container overflow-hidden"
          style={{ height: '85vh' }}
        >
          <div ref={divRef} className="overflow-auto" style={{ height: '90%' }}>
            <TermAndPrivacy type={type} />
          </div>
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: '10%' }}
          >
            <button
              type="button"
              className="btn w-auto btn-primary mt-2 py-2 px-5 font-size-18"
              onClick={() => {
                setVisible(false)
                if (onClickButton) {
                  onClickButton()
                }
              }}
            >
              동의하다
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default forwardRef(TermAndPrivacyPopUp)
