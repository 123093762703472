import { TYPES } from '../types'

export const INITIAL_STATE: { searchInput: string; isUpdateSearchInput: boolean } = {
  searchInput: '',
  isUpdateSearchInput: false,
}

const topBarReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TYPES.CHANGE_SEARCH_INPUT: {
      return {
        ...state,
        searchInput: action.payload.searchInput,
      }
    }

    case TYPES.UPDATE_SEARCH_INPUT: {
      return {
        ...state,
        isUpdateSearchInput: true,
      }
    }

    case TYPES.UPDATE_DONE_SEARCH_INPUT: {
      return {
        ...state,
        isUpdateSearchInput: false,
      }
    }

    case TYPES.CLEAR_SEARCH_INPUT:
      return INITIAL_STATE

    default:
      return state
  }
}

export default topBarReducer
