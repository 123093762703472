import { takeEvery, all } from 'redux-saga/effects'
import { TYPES } from '../redux/types'
import { changePasswordSaga, getUserInfoSaga, loginSaga, logoutSaga, signUpSaga } from './auth'

export default function* rootSaga() {
  try {
    yield all([
      takeEvery(TYPES.SAGA_LOGIN, loginSaga),
      takeEvery(TYPES.SAGA_LOGOUT, logoutSaga),
      takeEvery(TYPES.SAGA_FORCE_LOGOUT, logoutSaga),
      takeEvery(TYPES.SAGA_SIGN_UP, signUpSaga),
      takeEvery(TYPES.SAGA_GET_PROFILE, getUserInfoSaga),
      takeEvery(TYPES.SAGA_CHANGE_PASSWORD, changePasswordSaga),
    ])
  } catch (error) {
    console.log('Error in Saga', error)
  }
}
