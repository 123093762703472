import { toast } from 'react-toastify'
import ToastMessage, { MessageType } from '../../components/ToastMessage'

export class ToastHelper {
  static showMessage(title: string, message: string, type?: MessageType) {
    toast(<ToastMessage title={title} message={message} type={type} />)
  }

  static clearAllMessage() {
    toast.dismiss()
  }
}
