import { TYPES } from '../types'

export const INITIAL_STATE: { isOpenSidebar: boolean } = {
  isOpenSidebar: false,
}

const sideBarReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TYPES.TOGGLE_SIDEBAR: {
      return {
        ...state,
        isOpenSidebar: !state.isOpenSidebar,
      }
    }

    case TYPES.OPEN_SIDEBAR: {
      return {
        ...state,
        isOpenSidebar: true,
      }
    }

    case TYPES.CLOSE_SIDEBAR: {
      return {
        ...state,
        isOpenSidebar: false,
      }
    }

    case TYPES.CLEAR_SIDEBAR:
      return INITIAL_STATE

    default:
      return state
  }
}

export default sideBarReducer
