import ReactDOM from 'react-dom'
// import './assets/styles/index.scss'
import App from './App'
import configureStore, { history } from './redux/index'
import reportWebVitals from './reportWebVitals'

//import UI CSS
import 'bootstrap/dist/css/bootstrap.css'
import './styles/css/typography.css'
import './styles/css/style.css'
import './styles/css/responsive.css'

//import icon font
import 'remixicon/fonts/remixicon.css'
import 'font-awesome/css/font-awesome.min.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';

//import carousel and animation
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

//import js
import 'bootstrap/dist/js/bootstrap.bundle'
import 'animate.css';
import './styles/js/custom'

export const store = configureStore()

ReactDOM.render(<App store={store} history={history} />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
