export const elementType = {
  title: 'title',
  paragraph: 'paragraph',
  subtitle: 'subtitle',
  strongText: 'strongText',
  list: 'list',
  listWithNoMark: 'listWithNoMark',
  listWithMarkNumber: 'listWithMarkNumber',
  italyParagraph: 'italyParagraph',
  note: 'note',
  breakLine: 'breakLine',
}

type privacyItem = {
  type: string
  text?: string
  arrayText?: Array<string>
}

export const privacyStatement: Array<privacyItem> = [
  {
    type: elementType.title,
    text: 'G스쿨 개인정보취급방침',
  },

  // summary
  {
    type: elementType.paragraph,
    text: `G스쿨 운영사인 (주)와이에스디 (이하 "회사"라 함)는 정보통신망이용촉진 
    및 정보보호 등에 관한 법률 등 모든 관련 법령을 준수하며, 관련 법령에 의거한 개인정보취급방침을
     정하여 회원의 개인정보가 어떤 목적으로 수집되고 있으며 어떻게 보호조치가 되고 있는지 알려드립니다. 또한 개인정보취급방침을 개정하는 경우 웹사이트 공지사항을 통하여 공지할 것입니다.`,
  },
  {
    type: elementType.paragraph,
    text: `회사의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.`,
  },
  {
    type: elementType.listWithMarkNumber,
    arrayText: [
      '수집하는 개인정보의 항목 및 수집방법',
      '개인정보의 수집 및 이용목적',
      '개인정보 공유 및 제공',
      '개인정보의 취급위탁',
      '개인정보의 보유 및 이용기간',
      '개인정보 파기절차 및 방법',
      '이용자 및 법정대리인의 권리와 그 행사방법',
      '개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항',
      '아동의 개인정보 보호',
      '링크사이트',
      '의견수렴 및 불만처리',
      '개인정보의 기술적/관리적 보호 대책',
      '개인정보관리책임자 및 담당자의 연락처',
      '고지의 의무',
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 1
  {
    type: elementType.subtitle,
    text: `1. 수집하는 개인정보의 항목 및 수집방법`,
  },
  {
    type: elementType.paragraph,
    text: `가. 수집하는 개인정보의 항목`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 회원에게 다양한 서비스를 제공하기 위해 다음과 같은 개인정보를 수집하고 있습니다. 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)와 주민등록번호는 수집하지 않습니다.`,
  },
  {
    type: elementType.italyParagraph,
    text: `<필수입력사항>`,
  },
  {
    type: elementType.paragraph,
    text: `이름, 아이디, 비밀번호, 우편물수령지 주소 및 전화번호(직장 또는 자택), 휴대전화번호, 이메일주소, 생년월일, 마케팅 정보수신 동의 여부, 본인확인기관을 통해 받는 결과값(CI),만 14세 미만 회원의 법정대리인 이름 및 본인확인기관을 통해 받는 결과값(CI), 법정대리인과의 연령 차이, 준비중인시험 분야 및 전공 지역`,
  },
  {
    type: elementType.italyParagraph,
    text: `<선택입력항목>`,
  },
  {
    type: elementType.paragraph,
    text: `결혼 여부, 결혼기념일, 직업, 최종학력, 학교 및 전공, 응시 횟수, 응시 년도, 가입경로`,
  },
  {
    type: elementType.italyParagraph,
    text: `<서비스 이용 또는 사업처리 과정에서 생성 수집되는 각종 거래 및 개인 성향 정보>`,
  },
  {
    type: elementType.paragraph,
    text: `서비스 이용기록, 접속로그, 쿠키, 접속IP정보, 결제기록 등`,
  },
  {
    type: elementType.paragraph,
    text: `나. 개인정보 수집방법`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 다음과 같은 방법으로 개인정보를 수집합니다.`,
  },
  {
    type: elementType.list,
    arrayText: [
      '홈페이지 이용',
      '로그 분석 프로그램을 통한 생성정보 수집',
      "'쿠키(cookie)'에 의한 정보 수집",
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 2
  {
    type: elementType.subtitle,
    text: `2. 개인정보의 수집 및 이용목적`,
  },
  {
    type: elementType.paragraph,
    text: `회사가 개인정보를 수집하는 것은 수집한 정보를 바탕으로 아래와 같이 원활한 G스쿨 서비스 제공을 하기 위한 것이며 보다 나은 서비스 혜택 제공을 위해 다양한 방법(전화, SMS, 우편 등)을 통해 서비스 관련 정보를 회원에게 제공할 수 있습니다.
    단 회원이 서비스 혜택 정보 제공을 원치 않는다는 의사를 명확히 밝히는 경우 정보 제공을 하지 않으며 이 경우 회원에게 정보 미 제공으로 인한 불이익이 발생하더라도 회사가 책임지지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `가. 개인정보 수집 목적 세부 사항`,
  },
  {
    type: elementType.list,
    arrayText: [
      'G스쿨 서비스 제공 계약의 성립 및 유지 종료를 위한 본인 식별 및 실명확인, 가입의사 확인, 회원에 대한 고지 사항 전달 등',
      'G스쿨 서비스 제공을 위한 ID 제공, 포인트 적립 및 사용, 포인트 정산, 요금정산, 상품구매 및 요금결제, 고객센터 운영, 불량회원 부정이용 방지 및 비인가 사용방지, 이벤트 및 마케팅 기획/관리, 서비스 개발을 위한 연구/조사, 물품 등의 배송 등',
      'G스쿨 서비스 관련 각종 이벤트 및 행사 관련 정보안내를 위한 전화, SMS, 이메일, DM 발송 등의 마케팅 활동 등',
      'G스쿨 상품/서비스에 대한 제반 마케팅(대행포함) 활동 관련 전화, SMS, 이메일, DM 발송을 통한 마케팅, 판촉행사 및 이벤트, 사은행사 안내 등',
      '만 14세 미만 회원의 개인정보 수집 시 법정 대리인 동의여부 확인 및 추후 법정 대리인 본인확인',
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 3
  {
    type: elementType.subtitle,
    text: `3. 개인정보의 제공`,
  },
  {
    type: elementType.paragraph,
    text: `G스쿨은 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 단, 아래의 경우에는 예외로 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `가. 이용자가 사전에 동의 한 경우`,
  },
  {
    type: elementType.paragraph,
    text: `나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우`,
  },
  {
    type: elementType.breakLine,
  },

  // section 3
  {
    type: elementType.subtitle,
    text: `4. 개인정보의 취급위탁`,
  },
  {
    type: elementType.paragraph,
    text: `G스쿨은 고객님의 동의 없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 합니다.`,
  },
  {
    type: elementType.breakLine,
  },

  // section 5
  {
    type: elementType.subtitle,
    text: `5. 개인정보의 보유 및 이용기간`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 수집된 회원의 개인정보 수집 목적 또는 제공 받은 목적이 달성되면 지체 없이 폐기함을 원칙으로 합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `가. 관련법령에 의한 정보보유 사유`,
  },
  {
    type: elementType.paragraph,
    text: `상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.`,
  },
  {
    type: elementType.list,
    arrayText: [
      '서비스 이용기록, 계약, 청약철회, 결제 등에 관한 기록: 5년',
      '대금결제 및 재화 등의 공급에 관한 기록: 5년',
      '소비자의 불만 또는 분쟁처리에 관한 기록: 3년',
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 6
  {
    type: elementType.subtitle,
    text: `6. 개인정보 파기절차 및 방법`,
  },
  {
    type: elementType.paragraph,
    text: `이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `회사의 개인정보 파기절차 및 방법은 다음과 같습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `가. 파기절차`,
  },
  {
    type: elementType.list,
    arrayText: [
      '이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.',
      '동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.',
    ],
  },
  {
    type: elementType.paragraph,
    text: `나. 파기방법`,
  },
  {
    type: elementType.list,
    arrayText: [
      '종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.',
      '전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.',
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 7
  {
    type: elementType.subtitle,
    text: `7. 이용자 및 법정대리인의 권리와 그 행사방법`,
  },
  {
    type: elementType.list,
    arrayText: [
      `회원 및 법정대리인은 언제든지 등록되어 있는 자신 또는 만 14세 미만의 아동의 개인정보를 조회
       하거나 수정 가능하며 회원 탈퇴를 통해 제공한 개인정보 이용에 관한 동의를 철회할 수 있습니다.
        회원 정보 조회, 변경 및 탈퇴 신청은 회사가 운영하는 인터넷 사이트(www.g-school.com, www.g-school.co.kr, 이하 "G스쿨 홈페이지"라 함) 또는 G스쿨 고객센터를 통하여 할 수 있습니다.
      이 외에도 개인정보관리책임자를 통해 서면, 전화 또는 이메일로도 해당 업무가 처리 가능합니다.`,
      '회원이 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제3자에게 제공하지 않습니다. 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.',
      "G스쿨은 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 'G스쿨이 수집하는 개인정보의 보유 및 이용기간'에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.",
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 8
  {
    type: elementType.subtitle,
    text: `8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항`,
  },
  {
    type: elementType.paragraph,
    text: `가. 쿠키란?`,
  },
  {
    type: elementType.list,
    arrayText: [
      "회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.",
      '쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.',
      '쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.',
    ],
  },
  {
    type: elementType.paragraph,
    text: `나. 회사의 쿠키 사용 목적`,
  },
  {
    type: elementType.paragraph,
    text: `쿠키는 G스쿨 홈페이지에 회원이 접속을 하면 접속 빈도나 방문 시간 등을 분석하고 고객의 자취 추적, 이벤트 참여 정보 및 방문 횟수 등을 파악하여 마케팅이나 개인화 서비스의 제공 등을 위하여 사용합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `다. 쿠키의 설치/운영 및 거부`,
  },
  {
    type: elementType.list,
    arrayText: [
      '이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.',
      '다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 G스쿨 일부 서비스는 이용에 어려움이 있을 수 있습니다.',
      '쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.',
    ],
  },
  {
    type: elementType.note,
    text: `설정방법 예 (인터넷 익스플로어의 경우): 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`,
  },
  {
    type: elementType.breakLine,
  },

  // section 9
  {
    type: elementType.subtitle,
    text: `9. 아동의 개인정보 보호`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 만 14세 미만 아동의 가입 시 개인정보를 수집하거나 제3자에게 제공하고자 하는 경우 법정대리인의 동의를 받습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `만 14세 미만 아동의 정보 수집이 필요한 경우 법정대리인의 동의를 받습니다. 아동의 법정대리인은 아동의 개인정보에 대한 열람, 정정 및 삭제를 요청하실 수 있으며 이를 위해 webmarster@g-school.co.kr, 02-823-5252으로 연락하시면 확인절차를 거쳐 필요한 조치를 취하겠습니다.`,
  },
  {
    type: elementType.list,
    arrayText: [
      '아동(청소년)보호 책임자: 이희섭, manager@g-school.co.kr',
      '아동(청소년)보호 담당자: 김학채, webmaster@g-school.co.kr',
      '문의처: 전화번호 02-823-5252 / 사이트: www.g-school.co.kr, www.g-school.com',
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 10
  {
    type: elementType.subtitle,
    text: `10. 링크사이트`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 회원님께 회사가 운영하는 G스쿨 홈페이지를 통해 제휴사 또는 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 외부 인터넷 사이트로부터 회원이 제공받는 서비스나 자료의 유용성 또는 회원과 당해 인터넷 사이트의 거래에 대해서 회사는 어떠한 책임도 지지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `링크 페이지 또는 연결된 페이지로 옮겨갈 경우 해당 인터넷 사이트의 개인정보취급방침은 회사와 무관하므로 새로 방문한 인터넷 사이트의 정책을 검토해 보시기 바랍니다.`,
  },
  {
    type: elementType.breakLine,
  },

  // section 11
  {
    type: elementType.subtitle,
    text: `11. 개인정보의 기술적/관리적 보호 대책`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `가. 비밀번호 암호화`,
  },
  {
    type: elementType.paragraph,
    text: `회원의 개인정보는 ID와 비밀번호에 의해 보호되며, 파일 및 전송 데이터, 고객수집정보 등의 중요한 데이터는 암호화하는 등 별도의 보안기능을 통해 보호되고 있습니다.
     단, 다음의 기술적 대책에도 불구하고 해커의 침입 및 신종 바이러스 침투와 같은 사고 발생 당시의 기술로 예방,
      조치할 수 없는 불가항력 사유로 인하여 발생한 개인정보의 분실, 도난, 누출, 변조 또는 훼손에 대하여는 회원에게 책임을 지지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `나. 해킹 등에 대비한 대책`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `바해킹 등 외부로부터의 비인가 접근을 통제하기 위하여 침입차단시스템을 설치/운영하여 있으며 서버에의 접근은 업무 역할에 따라 필요한 최소한의 인원으로 제한하고 있습니다.`,
  },
  {
    type: elementType.breakLine,
  },

  // section 12
  {
    type: elementType.subtitle,
    text: `12. 의견수렴 및 불만처리`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 고객의 의견을 소중하게 생각하며 고객과의 원활한 의사소통을 위해 고객센터를 운영하고 있습니다. [고객센터]의 연락처는 다음과 같습니다.`,
  },
  {
    type: elementType.list,
    arrayText: [
      '전화번호 : 02-823-5252',
      '전화상담 시간 : 09:00 ~ 18:00',
      '상담일 : 월~금 (공휴일 휴무)',
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 13
  {
    type: elementType.subtitle,
    text: `13. 개인정보관리책임자 및 담당자의 연락처`,
  },
  {
    type: elementType.paragraph,
    text: `회사는 수집된 회원의 개인정보 관리를 위해 개인정보 관리책임자를 다음과 같이 지정하여 관리하고 있으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.`,
  },
  {
    type: elementType.list,
    arrayText: [
      '개인정보관리 책임자: 김학채, webmaster@g-school.co.kr',
      '개인정보관리 담당자: 이희섭, manager@g-school.co.kr',
      '문의처: 전화번호 02-823-5252 / 사이트: www.g-school.co.kr, www.g-school.com',
    ],
  },
  {
    type: elementType.breakLine,
  },

  // section 14
  {
    type: elementType.subtitle,
    text: `14. 고지의 의무`,
  },
  {
    type: elementType.paragraph,
    text: `본 개인정보취급방침을 포함한 기타 개인정보 보호에 대한 상세한 내용은 회사가 운영하는 G스쿨 홈페이지 첫 화면에 공개함으로써 회원님께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다. 본 개인정보취급방침의 내용은 수시로 변경될 수 있으므로 사이트를 방문하실 때마다, 이를 확인하시기 바랍니다.`,
  },
  {
    type: elementType.paragraph,
    text: `본 개인정보취급방침이 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보취급방침을 시행하기 전에 G스쿨 홈페이지를 통해 변경 이유 및 내용 등을 공지합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `개인정보 취급 방침 개정 이력`,
  },
  {
    type: elementType.paragraph,
    text: `본 개인정보취급방침이 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보취급방침을 시행하기 전에 G스쿨 홈페이지를 통해 변경 이유 및 내용 등을 공지합`,
  },
  {
    type: elementType.breakLine,
  },
]

export const purchaseTerm: Array<privacyItem> = [
  {
    type: elementType.title,
    text: '(구매약관)',
  },

  //summary
  {
    type: elementType.listWithNoMark,
    arrayText: [
      '제 1조	목적',
      '제 2조	약관의 효력 및 변경',
      '제 3조	약관 외 준칙',
      '제 4조	용어의 정의',
      '제 5조	이용신청',
      '제 6조	이용계약의 성립',
      '제 7조	이용신청에 대한 승낙 유보 및 서비스 이용의 제한',
      '제 8조	서비스의 제공 내용 및 요건 등',
      '제 9조	서비스의 제공 기간과 이용시간',
      '제 10조	상품의 구매',
      '제 11조	상품의 결제',
      '제 12조	청약철회 등에 관한 규정',
      '제 13조	서비스의 변경 및 중단',
      '제 14조	상품의 지적재산권',
      '제 15조	게시물의 지적재산권',
      '제 16조	포인트 또는 마일리지 제도 이용',
      '제 17조	회사의 의무',
      '제 18조	회원의 의무',
      '제 19조	회원에 대한 통지',
      '제 20조	ID와 PASSWORD 관리에 대한 의무와 책임',
      '제 21조	개인정보의 변경, 보호',
      '제 22조	계약해지 및 이용제한',
      '제 23조	양도 금지',
      '제 24조	손해배상',
      '제 25조	면책사항',
      '제 26조	분쟁의 해결, 관할법원',
    ],
  },
  {
    type: elementType.breakLine,
  },

  //section 1
  {
    type: elementType.subtitle,
    text: `제 1장 총칙`,
  },

  //rule 1
  {
    type: elementType.strongText,
    text: `제 1조 (목적)`,
  },
  {
    type: elementType.paragraph,
    text: `본 약관은 주식회사 와이에스디가 운영하는 E-Book서비스인 지스쿨E-Book서비스(이하 “본 서비스”)를 이용하여 디지털 콘텐츠 등을 거래하는 회원 간 또는 회원과 회사 간의 권리, 의무, 책임사항 및 회원의 본 서비스 이용조건 및 절차 등 기본적인 사항을 규정하여 회원의 권익을 보호함을 목적으로 합니다.`,
  },

  //rule 2
  {
    type: elementType.strongText,
    text: `제 2조 (약관의 효력 및 변경)`,
  },
  {
    type: elementType.paragraph,
    text: `① 본 약관은 본 서비스를 이용하고자 하는 모든 회원에게 그 효력을 발생합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 본 약관의 내용은 회사가 본 서비스의 인터넷 홈페이지(http://www.g-school.co.kr 이하 "홈페이지") 또는 본 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지하고, 회원이 이에 동의하면 효력이 발생합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 필요하다고 인정되면 본 약관을 변경할 수 있으며,
     회사가 약관을 변경할 때에는 적용일자와 변경사유를 밝혀 본 조 제2항과 같은 방법으로 그 적용일자 7일 전부터 공지합니다.
      다만, 회원에게 불리한 약관 변경의 경우 그 적용일자 30일 전부터 공지하며, 이메일(E-mail) 또는 문자메시지(SMS) 등으로 회원에게 개별 통지합니다.
    (회원이 연락처를 기재하지 않았거나 변경하여 개별 통지가 어려울 때에는 홈페이지에 변경 약관을 공지하는 것을 개별 통지한 것으로 간주합니다.)`,
  },
  {
    type: elementType.paragraph,
    text: `④ 회사가 본 조 제3항에 따라 변경 약관을 공지하거나 통지하면서, 회원이 약관 변경 적용일까지 거부 의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 변경된 약관의 효력 발생일까지 약관 변경에 대한 거부 의사를 표시하지 않을 때에는 회원이 변경 약관에 동의한 것으로 간주합니다.
    회원은 변경된 약관에 동의하지 않으면 본 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑤ 본 약관에 동의하는 것은 회사가 운영하는 본 서비스의 홈페이지를 정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에 대하여 회사는 책임이 없습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑥ 회사는 이용자가 요구하면 본 약관의 사본을 이용자에게 교부하며, 이용자가 홈페이지에서 본 약관을 인쇄할 수 있도록 조치합니다.`,
  },

  //rule 3
  {
    type: elementType.strongText,
    text: `제3조 (약관 외 준칙)`,
  },
  {
    type: elementType.paragraph,
    text: `① 본 약관에 밝히지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령 및 회사가 정한 서비스의 세부이용지침 등의 규정을 따릅니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사는 필요하면 특정 서비스에 적용될 사항(이하 “개별약관”)을 정하여 이를 제2조 제2항의 방법에 따라 공지할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 필요하면 본 서비스 이용과 관련된 세부이용지침을 정하여 이를 제2조 제2항의 방법에 따라 공지할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 본 약관과 관련하여 회사의 정책 변경, 법령의 제•개정 또는 공공기관의 고시나 지침 등에 따라 회사가 홈페이지의 공지사항 게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성합니다.`,
  },

  //rule 4
  {
    type: elementType.strongText,
    text: `제4조 (용어의 정의)`,
  },
  {
    type: elementType.paragraph,
    text: `① 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.`,
  },
  {
    type: elementType.listWithMarkNumber,
    arrayText: [
      '본 서비스: 회사 또는 판매사가 제공하는 상품과 같은 디지털 콘텐츠를 본 약관에 제시된 조건에 따라 구매회원이 구매할 수 있도록 회사에서 제공하는 디지털 콘텐츠 중개 서비스 및 관련 정보 제공•유통 서비스',
      '상품: 회사에서 제공하는 양식에 맞추어 판매회원이 판매 또는 전송을 위하여 등록한 프로그램 또는 콘텐츠 등의 총칭',
      '회원: 홈페이지에 접속하여 본 약관에 동의하고, 아이디(ID)와 비밀번호(PASSWORD)를 발급받거나 휴대용 단말기 또는 PC용 소프트웨어에서 본 약관에 동의하고, 해당 단말기를 등록하여 본 서비스를 이용할 수 있는 개인이나 법인 고객',
      '아이디(ID): 회원 식별과 회원의 본 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 영문자와 숫자의 조합.',
      '비밀번호(PASSWORD): 회원의 정보 보호를 위해 회원 자신이 설정한 문자, 숫자 및 특수문자의 조합.',
      '운영자: 본 서비스의 전반적인 관리와 원활한 운영을 위하여 회사가 선정한 자',
      '게시물: 회원이 본 서비스를 이용하면서 본 서비스에 게시한 부호ㆍ문자ㆍ소리ㆍ화상ㆍ동영상ㆍ기타 정보 형태의 글, 사진 및 각종 파일과 링크',
      '문자메시지(SMS) 인증: 회사가 고객의 이동전화로 문자메시지(SMS) 난수를 발송했을 때 고객이 해당 번호를 홈페이지에 등록하도록 함으로써 인증을 시도하는 고객이 가입한 이동통신사를 확인하는 절차',
      '본인 인증: 회원의 이름과 본인임을 확인할 수 있는 정보(외국인 회원은 이름과 외국인등록번호, 사업자회원은 상호와 사업자등록번호)가 일치하는지를 확인하는 절차',
      '이메일(E-mail) 인증: 회사가 회원이 입력한 이메일(E-mail) 주소로 인증용 이메일(E-mail)을 발송하고, 고객이 해당 이메일(E-mail)을 통해 홈페이지에 접속하도록 함으로써 해당 이메일(E-mail) 주소의 유효성을 확인하는 절차',
      '앱스토어결제: 회원이 본 서비스를 통해 유료상품을 구매하거나 본 서비스를 통해 다운로드 받은 상품 내에서 추가상품을 구매할 때 적용되는 회사가 제공하는 결제수단 및 그 서비스',
    ],
  },
  {
    type: elementType.paragraph,
    text: `② 본 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계 법령과 서비스별 안내에서 정하는 바를 따릅니다.`,
  },
  {
    type: elementType.breakLine,
  },

  //section 2
  {
    type: elementType.subtitle,
    text: `제2장 서비스 이용계약`,
  },

  //rule 5
  {
    type: elementType.strongText,
    text: `제5조 (이용신청)`,
  },
  {
    type: elementType.paragraph,
    text: `① 본 서비스에 가입하여 서비스를 이용하고자 하는 자는 휴대용 단말기 또는 PC용 소프트웨어에서 본 약관에 동의하고, 해당 단말기를 등록하여 가입 절차를 밟습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 본 서비스에 가입하여 서비스를 이용하고자 하는 자는 회사에서 제공한 회원가입 신청양식에 따라 필요사항을 기재하여야 합니다. 회원 가입 때 기재할 항목은 필수 기재 항목과 선택 기재 항목으로 구분되어 있으며 필수 기재 항목은 반드시 입력해야 합니다. 선택 기재 항목 중 일부 항목은 상품을 구매할 때 반드시 입력해야 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 본 서비스에 가입하여 이용하고자 하는 자에게 “문자메시지(SMS) 인증”, 기타 본인을 확인할 수 있는 인증 절차를 요구할 수 있습니다. 이때 본 서비스에 가입하여 이용하고자 하는 자는 해당 인증을 수행하는 등 가입 절차를 완료해야 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 전 항에 따른 인증의 정확성을 위하여 본 서비스에 가입하여 이용하고자 하는 자는 본인 명의의 이동전화를 이용하여 인증절차를 수행해야 합니다. 본인의 관리 소홀로 타인이 본인 명의의 인증절차를 수행하거나 본인이 타인의 명의를 무단으로 사용하여 인증을 수행했을 때 발생하는 불이익과 기타 손해에 대해 회사는 책임을 지지 않습니다.`,
  },

  //rule 6
  {
    type: elementType.strongText,
    text: `제6조 (이용계약의 성립)`,
  },
  {
    type: elementType.paragraph,
    text: `① 이용계약은 회원이 되고자 하는 자(이하 “가입신청자”)가 본 약관의 내용에 대하여 동의한 후 회원가입 신청 양식에 따라 필요한 항목을 기재하여 제출하는 방법으로 회원가입을 신청하고, 회사가 위 신청을 승낙하면 체결됩니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 본 서비스는14세 미만 아동의 구매회원 서비스는 허용되지 않습니다,`,
  },

  //rule 7
  {
    type: elementType.strongText,
    text: `제7조 (이용신청에 대한 승낙 유보 및 서비스 이용의 제한)`,
  },
  {
    type: elementType.paragraph,
    text: `① 제5조에 따른 가입신청자에게 회사는 원칙적으로 본 서비스의 이용을 승낙합니다. 단, 회사는 다음 각 호에 해당하는 신청은 그 사유가 해소될 때까지 승낙을 유보하거나, 승낙을 거부할 수 있습니다.`,
  },
  {
    type: elementType.listWithMarkNumber,
    arrayText: [
      '다른 사람의 명의를 사용하거나 이동전화 명의자의 허락 없이 문자메시지(SMS) 인증, 범용공인 인증 또는 신용카드 인증을 수행하는 등 이용자를 등록할 때 허위로 신청하는 경우',
      '회사에 의하여 이용계약이 해지된 날로부터 2개월 이내에 재이용을 신청하는 경우',
      '기술상 서비스를 제공할 수 없는 경우',
      '고객 등록 사항을 빠뜨리거나 잘못 기재하여 신청하는 경우',
      '이메일(E-mail) 승인 또는 문자메시지(SMS) 인증 등 회사가 정한 인증 절차를 완료하지 않은 경우',
      '만 14세 미만의 아동이 개인정보의 수집•이용 등에 대해 법정 대리인의 동의를 얻지 않은 경우',
      '가입신청자가 회원 자격을 잃은 사실이 있는 경우, 단, 회원 자격을 잃은 날로부터 1년이 지난 자로서 회사의 재가입 승낙을 얻었을 때에는 그렇지 않습니다.',
      '회사로부터 회원자격정지 조치 등을 받은 회원이 그 조치 기간에 이용계약을 임의로 해지하고 재이용을 신청하는 경우',
      '사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우',
      '자금을 세탁할 목적 또는 자금세탁으로 의심되는 경우',
      '기타 회사가 정한 이용신청 요건을 만족시키지 않았거나 위법 또는 부당한 이용신청임이 확인된 경우 또는 회원의 책임 있는 사유로 회사가 승낙할 수 없는 경우',
    ],
  },
  {
    type: elementType.paragraph,
    text: `② 본 조 제1항에 따라 회사가 회원가입 신청의 승낙을 유보하거나 거절했을 때에는 회사는 이용신청자에게 승낙 유보의 사유, 승낙 가능시기 또는 승낙에 필요한 추가 요청 정보나 자료 등 기타 승낙 유보, 거절과 관련된 사항을 해당 서비스 화면에 게시하거나 이메일(E-mail) 등을 통하여 통지합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 본 서비스의 품질 향상 등 필요한 경우가 있으면, 회원을 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴, 기타 필요한 사항을 세분하여 그 이용에 차등을 둘 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 회사는 회원에게 영화 및 비디오물의 진흥에 관한 법률, 청소년보호법 기타 관련 법령에 따른 등급 및 연령 준수를 위하여 이용제한이나 등급별 제한, 기타 필요한 조치를 할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑤ 회원이 본 약관에 동의함은 본 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.`,
  },
  {
    type: elementType.breakLine,
  },

  //section 3
  {
    type: elementType.subtitle,
    text: `제3장 서비스 이용`,
  },

  //rule 8
  {
    type: elementType.strongText,
    text: `제8조 (서비스의 제공 내용 및 요건 등)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회사는 회원에게 다음 각 호의 서비스를 제공합니다.`,
  },
  {
    type: elementType.listWithNoMark,
    arrayText: [
      '1. 전자상거래 서비스: 회사가 본 서비스를 통하여 회원과 상품의 거래가 이루어질 수 있도록 온라인 거래장소를 제공하는 서비스 및 관련 부가서비스를 말합니다.',
    ],
  },
  {
    type: elementType.list,
    arrayText: ['가. 구매 관련 지원 서비스', '나. 상품검색정보 서비스'],
  },
  {
    type: elementType.listWithNoMark,
    arrayText: [
      '2. 개인화 서비스: 고객의 관심에 부합하는 이벤트 기획 및 개인별 최적화된 상품을 제공하는 개인 맞춤형 서비스와 관련 부가서비스를 말합니다.',
    ],
  },
  {
    type: elementType.paragraph,
    text: `② 본 서비스를 이용하려면 PC•스마트폰•태블릿 PC 등 인터넷 연결이 가능하여 인터넷상의 콘텐츠를 검색•구매•이용•보관할 수 있는 기능을 갖는 일체의 단말기가 필요하며, 구매회원의 디바이스 환경에 따라 업데이트나 업그레이드가 필요할 수 있습니다.`,
  },

  //rule 9
  {
    type: elementType.strongText,
    text: `제9조 (서비스의 제공 기간과 이용시간)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회원에게 본 서비스를 제공하는 기간은 회원의 본 서비스 이용신청을 회사가 승낙한 날로부터 본 서비스의 이용계약을 해지하거나 본 서비스를 종료할 때까지입니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 서비스를 일정 범위로 나누어 각각의 범위에서 이용할 수 있는 시간을 별도로 정할 수 있으며 그럴 때에는 그 내용을 공지합니다.`,
  },

  //rule 10
  {
    type: elementType.strongText,
    text: `제10조 (상품의 구매)`,
  },
  {
    type: elementType.paragraph,
    text: `① 구매계약은 회원이 회사가 제시한 상품의 판매 조건에 응하고 구매하겠다는 의사표시를 했을 때 체결됩니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사는 앱스토어 결제를 구매회원에게 제공하여 구매회원이 휴대전화, 신용카드 등 기타의 방법으로 상품 구매 대금을 결제할 수 있게 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 상품 판매 대금의 결제와 관련하여 구매회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 구매회원이 부담해야 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 회사는 구매한 상품에 한하여 구매회원의 상품 구매 계약 체결 내용을 나의 구매 내역에서 확인할 수 있도록 조치합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑤ 상품은 구매일과 상관없이 다시 내려받을 수 있으며(특정 상품군별로 다시 내려받는 기간은 별도로 정해질 수 있습니다) 그 이후에 구매회원이 훼손하거나 삭제하여 발생한 손실에 대하여 회사나 판매회원은 책임을 지지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑥ 미성년자인 회원이 법정 대리인(부모 등)의 동의 없이 상품을 구매하거나 계약 체결 후 법정 대리인이 추인하지 않으면 민법상 취소권을 행사할 수 있습니다.
    다만, 민법상 취소권이 제한될 때(미성년자가 성년자의 주민등록번호, 결제 정보 등을 회사에 제공하여 성년으로 믿게 한 경우, 회사가 통신과금서비스 이용 동의를 이미 얻은 경우 등)에는 취소할 수 없습니다.`,
  },

  //rule 11
  {
    type: elementType.strongText,
    text: `제11조 (상품의 결제)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회사는 상품의 가격을 정합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 구매회원은 회사에게 상품 판매 대금을 직접 지급해야 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 구매회원이 상품을 결제할 때는 결제대행사에 지급해야 하는 결제대행수수료를 포함하여 결제합니다. 결제대행수수료는 결제대행사가 구매회원이 상품을 결제하는 데 드는 비용입니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 회사는 필요하다고 인정하면 본 조 제1항과 제2항에 규정된 사항을 변경할 수 있습니다.`,
  },

  //rule 12
  {
    type: elementType.strongText,
    text: `제12조 (청약철회 등에 관한 규정)`,
  },
  {
    type: elementType.paragraph,
    text: `① 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법령이 정하는 바에 따라 구매회원은 회사 또는 판매회원을 상대로 청약철회, 반품 요청 등을 할 수 있습니다. 다만, 판매회원이 제공하는 외부결제를 통하여 구매한 상품인 경우 해당 상품의 청약철회, 반품 등 요청의 상대방은 판매회원이 됩니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 구매회원이 구매하는 디지털 상품은 구매 즉시 이용할 수 있는 상태가 되거나 이용하는 것으로 볼 수 있다면 그 상품의 성격상 그리고 관계 법령(전자상거래 등에서의 소비자보호에 관한 법률, 콘텐츠산업진흥법상의 청약철회 불가 사유 해당 등)상 구매 후에는 청약철회가 불가능할 수 있습니다.
    다만, 그럴 때 회사는 청약철회가 불가능하다는 사실을 표시사항에 포함하거나 시험상품을 제공하는 등의 조치를 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 구매회원이 구매한 상품이 소비자의 사용 또는 소비로 재화의 가치가 현저히 감소한 것으로 볼 수 없는 경우 등 본 조 제2항의 청약철회가 제한되는 경우 외에는, 구매회원은 이미 구매 완료된 상품이라도 회사의 고객센터 (g-schoo5@daum.net)에 청약철회, 환불 요청 등을 할 수 있습니다.
    고객센터는 해당 요청을 심사하여 그 결과를 구매회원과 회사에 통보합니다`,
  },
  {
    type: elementType.paragraph,
    text: `④ 회사는 필요하면 청약철회 등을 요청하는 구매회원에게 청약 철회 등에 대한 자료의 제공을 요청할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑤ 청약 철회, 환급 요청 기간은 관련 법령(전자상거래 등에서의 소비자보호에 관한 법률 등)에서 규정하는 기간을 따르며, 구체적인 기간은 다음과 같습니다.`,
  },
  {
    type: elementType.listWithMarkNumber,
    arrayText: [
      '유료 상품을 공급받은 날 또는 상품 공급에 대한 계약서, 통지 등을 받은 날로부터 7일 이내',
      '공급된 상품의 내용이 표시•광고된 내용과 다르거나 계약 내용과 다르게 이행되었을 때에는 해당 상품을 공급받은 날로부터 3개월 이내, 또는 그 사실을 알거나 알 수 있었던 날로부터 30일 이내',
    ],
  },
  {
    type: elementType.paragraph,
    text: `⑥ 본 조 제3항에 따라 청약을 철회한 구매회원은 회사로부터 제공받은 상품을 반환해야 하며, 디지털 상품, 내려받은 콘텐츠 등과 같이 반환하더라도 동일한 상품이 구매회원에게 남아 있으면 해당 상품을 삭제해야 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑦ 회사는 본 조 제5항에 따라 관련 법령(전자상거래 등에서의 소비자보호에 관한 법률 등)에서 규정하는 기간 내에 지급받은 상품 대금이 있으면 환급하여야 하며, 이때 구매회원이 해당 상품을 이용하여 얻은 이익이 있으면 해당 금액을 공제하고 환급할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑧ 회사는 구매회원에게 대금을 환급할 때 이용대금의 결제와 동일한 방법으로 결제 대금의 전부 또는 일부를 환급하며, 동일한 방법으로 환급할 수 없을 때에는 대안을 제시하며 이용자의 선택에 따라 환급합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑨ 앱 마켓에서 제공하는 인앱결제를 통하여 구매한 상품에 대한 구매회원의 청약철회 요청이 있는 경우, 회사는 인앱결제로 상품을 구매한 각 앱 마켓 사업자의 청약철회 정책에 따라 앱 마켓에 대하여 대금지급의 정지·취소 요청 등 전자상거래 등에서의 소비자 보호에 관한 법률에 근거한 조치를 요청합니다.
    위와 같은 회사의 요청에 따른 해당 앱 마켓의 조치에 관하여는 해당 앱 마켓의 정책을 참조하여 주시기 바랍니다.`,
  },

  //rule 13
  {
    type: elementType.strongText,
    text: `제13조 (서비스의 변경 및 중단)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회사는 본 서비스를 변경하여 제공할 수 있습니다. 이때 회사는 변경될 서비스의 내용과 제공일자를 제24조에서 정한 방법으로 회원에게 공지하거나 통지합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사는 다음 각 호에 해당하면 본 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.`,
  },
  {
    type: elementType.listWithMarkNumber,
    arrayText: [
      '서비스용 설비의 보수 등 공사로 부득이한 경우',
      '회원이 회사의 영업활동을 방해하는 경우',
      '정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우',
      '서비스 제공업자와의 계약 종료 등과 같은 회사의 제반 사정으로 본 서비스를 유지할 수 없는 경우',
      '기타 천재지변, 국가비상사태 등 불가항력의 사유가 있는 경우',
    ],
  },
  {
    type: elementType.paragraph,
    text: `③ 회사가 본 조 제2항에 따라 본 서비스를 중단할 때에는 제24조 제2항에서 정한 방법으로 이용자에게 해당 사실을 통지합니다. 다만, 통제할 수 없는 사유로 본 서비스가 중단(운영자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)되어 회사가 미리 통지할 수 없을 때에는 그렇지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 회사는 본 서비스의 변경, 중단으로 발생하는 문제에 대해서 회사에 고의, 과실이 없는 한 어떠한 책임도 지지 않습니다.`,
  },

  //rule 14
  {
    type: elementType.strongText,
    text: `제14조 (상품의 지적재산권)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회사가 본 서비스를 이용하여 판매하는 상품이 타인의 저작권과 기타 권리를 침해했을 때 그에 대한 책임은 회사가 부담합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 구매회원은 본 서비스를 통해 회사로부터 상품의 사용권을 구매하는 것이며 상품의 저작권과 기타 지적 재산권을 구매하는 것은 아닙니다. 판매회원의 명시적인 허락 없이 상품의 무단복제•전송 등 저작권을 침해하는 행위는 허용되지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 구매회원은 구매한 상품을 가공•재판매•홍보 등 영리 목적으로 이용하거나 제3자에게 이용하게 할 수 없습니다.`,
  },

  //rule 15
  {
    type: elementType.strongText,
    text: `제15조 (게시물의 지적재산권)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회원이 본 서비스 내에 게시한 게시물에 대한 저작권과 그 관련 책임은 해당 회원에게 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사는 회원이 본 서비스 내에 게시한 게시물이 타인의 저작권 등 지적 재산권을 침해하더라도 이에 대해 민•형사상의 책임을 부담하지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 회원의 동의를 받고 게시물을 이용하며, 이 경우 회사는 회원의 게시물을 홍보 등의 목적으로 이용할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 회사는 회원이 이용계약을 해지하고 가입을 탈퇴하거나 본 약관에 따른 적법한 사유로 회원의 이용계약이 해지되었을 때에는 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑤ 회사가 작성한 저작물에 대한 저작권은 회사에 귀속됩니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑥ 회원은 본 서비스를 이용하여 얻은 정보를 가공•판매하는 행위 등 본 서비스에 게재된 자료를 영리 목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.`,
  },

  //rule 16
  {
    type: elementType.strongText,
    text: `제16조 (포인트 또는 마일리지 제도 이용)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회사는 회원에게 서비스 이용을 위하여 사이버머니, 캐쉬, 포인트 또는 마일리지(이하 "사이버머니등”)를 부여할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사는 사이버머니등의 획득, 사용 방법 등과 관련된 이용약관 또는 세부이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침을 따라야 합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 경영상•기술상 이유로 사이버머니등 중 유상 사이버머니등 (회원이 대가를 주고 구매한 사이버머니등을 의미합니다, 이하 같습니다.)
    서비스를 종료할 경우 종료일로부터 최소 30일 전까지 회원에게 통지하고, 통지 후 종료일까지 회원이 사용하지 않은 잔여 유상 사이버머니등에 대하여 회원의 신청이 있는 경우 회원에게 환급합니다.`,
  },
  {
    type: elementType.breakLine,
  },

  //section 4
  {
    type: elementType.subtitle,
    text: `제4장 계약당사자의 의무`,
  },

  //rule 17
  {
    type: elementType.strongText,
    text: `제17조 (회사의 의무)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회사는 본 서비스 제공과 관련하여 알게 된 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 관계 법령에 따른 수사상의 목적으로 관계 기관으로부터 요구를 받았거나 방송통신심의위원회로부터 요청이 있는 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그렇지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사는 본 서비스와 관련한 회원의 불만사항이 고객센터를 통하여 접수되면 이를 신속하게 처리해야 하며, 신속한 처리가 어렵다면 그 사유와 처리 일정을 본 서비스 화면에 게재하거나 이메일(E-mail) 등을 통하여 해당 회원에게 통지합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사가 제공하는 서비스로 회원에게 손해가 발생하였다면 회사는 그러한 손해가 회사의 고의나 과실 때문에 발생했을 때에만 책임을 부담하며, 그 책임의 범위는 통상손해 에 한정됩니다.다만, 특별한 사정으로 회원에게 손해가 발생하였을 때 회사가 그 사정을 알았거나 알 수 있었다면 회사는 배상할 책임이 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등 본 서비스의 운영, 유지와 관련한 법규를 지킵니다.`,
  },

  //rule 18
  {
    type: elementType.strongText,
    text: `제18조 (회원의 의무)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회원은 본 서비스를 이용할 때 다음 각 호에 해당하는 행위를 해서는 안 됩니다.`,
  },
  {
    type: elementType.listWithMarkNumber,
    arrayText: [
      '회사가 제공하는 서비스 이용방법을 따르지 않고 비정상적인 방법으로 본 서비스를 이용하거나 시스템에 접근하는 행위',
      '이용신청 또는 개인정보 변경 때 거짓된 정보를 적거나 다른 회원의 아이디(ID)와 비밀번호(PASSWORD), 카드정보, 계좌정보 등을 무단으로 사용하거나 부정하게 사용하는 행위',
      '회사의 직원이나 운영자로 가장하거나 사칭하여 또는 타인의 명의를 무단으로 사용하여 글을 게시하거나 메일을 발송하는 행위',
      '타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위',
      '다른 회원의 개인정보를 무단으로 수집•저장•공개하는 행위',
      '타인의 명예를 손상하거나 불이익을 주는 행위',
      '회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위',
      '공공질서와 미풍양속을 거스르는 내용의 부호•문자•소리•화상•동영상 등과 기타 정보 형태의 글, 사진 및 각종 파일과 링크 등을 타인에게 유포하는 행위',
      '기타 불법적이거나 부당한 행위',
    ],
  },
  {
    type: elementType.paragraph,
    text: `② 회원은 관계 법령, 본 약관의 규정, 이용안내 및 본 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 지켜야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안 됩니다.`,
  },

  //rule 19
  {
    type: elementType.strongText,
    text: `제19조 (회원에 대한 통지)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회사는 회사가 발급한 이메일(E-mail) 주소 또는 회원이 등록한 이메일(E-mail) 주소 또는 문자메시지(SMS) 등으로 회원에게 통지할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사가 불특정 다수 회원에게 통지할 때에는 본 서비스 게시판 등에 게시하는 것으로 개별 통지를 대신할 수 있습니다.`,
  },

  //rule 20
  {
    type: elementType.strongText,
    text: `제20조 (ID와 비밀번호 관리에 대한 의무와 책임)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회원은 자신의 아이디(ID)와 비밀번호(PASSWORD)를 철저히 관리해야 합니다. 아이디(ID)와 비밀번호(PASSWORD)의 관리 소홀, 부정 사용으로 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회원은 본인의 아이디(ID)와 비밀번호(PASSWORD)를 타인에게 이용하게 해서는 안 되며, 회원 본인의 아이디(ID)와 비밀번호(PASSWORD)를 도난당하거나 타인이 사용하고 있음을 알았을 때에는 바로 회사에 통보하고 회사의 안내가 있으면 그것을 따라야 합니다.`,
  },

  //rule 21
  {
    type: elementType.strongText,
    text: `제21조 (개인정보의 변경, 보호)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회원은 이용신청 때 입력한 사항이 변경되면 즉시 변경사항을 최신의 정보로 수정해야 합니다. 단, 이름, 회원 아이디(ID) 등 변경할 수 없는 사항은 수정할 수 없습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 정보를 수정하지 않아서 발생하는 손해는 해당 회원이 부담하며, 회사는 이에 대해 아무런 책임을 지지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 회원의 정보를 본 서비스를 제공할 목적 이외의 용도로 사용하거나 회원의 동의 없이 제3자에게 제공하지 않습니다. 단, 다음의 경우는 예외로 합니다.`,
  },
  {
    type: elementType.listWithMarkNumber,
    arrayText: [
      '서비스 제공에 관한 계약을 이행하고자 판매자에게 거래 내역, 정산 내역 제공 등에 필요한 최소한의 회원정보(성명, 연락처 등)를 제공하는 경우',
      '법률이 회원의 개인정보의 이용과 제3자에 대한 정보 제공을 허용하는 경우',
    ],
  },
  {
    type: elementType.paragraph,
    text: `④ 회사는 관련 법령이 정하는 바에 따라 회원 등록정보를 포함한 회원의 개인정보를 보호하고자 노력합니다. 회원의 개인정보 보호는 관련 법령 및 회사가 정하는 개인정보취급방침에 정한 바를 따릅니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑤ 회사는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로 수행함을 원칙으로 하지만, 필요하면 회원의 동의를 받거나 해당 내용을 통지한 후 위 업무의 일부 또는 전부를 회사가 선정한 회사에 위탁할 수 있습니다.
    `,
  },
  {
    type: elementType.breakLine,
  },

  //section 5
  {
    type: elementType.subtitle,
    text: `제5장 계약해지 및 이용제한`,
  },

  //rule 22
  {
    type: elementType.strongText,
    text: `제22조 (계약해지 및 이용제한)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회원은 다음 각 호에서 정한 바에 따라 본 서비스 이용계약을 해지할 수 있습니다.`,
  },
  {
    type: elementType.listWithMarkNumber,
    arrayText: [
      '회원은 언제든지 회사에 이용계약 해지의 의사를 통지하여 이용계약을 해지할 수 있습니다. 단, 해지의사를 통지하기 전에 현재 진행 중인 모든 상품의 거래를 완료하거나 철회 또는 취소해야 하며, 거래를 철회하거나 취소하여 발생하는 불이익은 회원 본인이 부담하여야 합니다.',
      '회원탈퇴로 발생한 불이익은 회원 본인이 책임져야 하며, 이용계약이 종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.',
      '회원이 임의 해지, 재가입 등을 반복적으로 하여 회사가 제공하는 할인쿠폰, 이벤트 혜택 등의 경제적 이익을 취하거나 이 과정에서 타인의 명의를 무단으로 사용하는 등의 불법과 편법 행위를 차단하기 위하여 회사는 회원의 회원탈퇴 후 1개월 동안 해당 회원의 아이디(ID), 성명, 탈퇴일자, 탈퇴사유 등 개인정보를 보관합니다.',
    ],
  },
  {
    type: elementType.paragraph,
    text: `② 회사는 제23조 제1항 위반 등을 이유로 회원과의 이용계약을 해지하거나 회원에게 서비스 이용을 제한하고자 할 때에는 미리 그 사유와 내용을 통지합니다. 다만, 제24조 제1항 각 호에 해당하는 행위로서 회원과 연락할 수 없거나 긴급을 요하는 경우에는 먼저 이용계약을 해지하거나 서비스 이용을 제한한 후에 통지할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 회원 가입 후 6개월 동안 본 서비스를 사용한 이력이 없는 회원에게는 본 서비스를 이용할지를 묻는 고지를 하고, 회사가 정한 기한 내에 답변이 없으면 이용계약을 해지할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 본 조 제2항과 제3항의 회사 조치에 대하여 회원은 회사가 정한 절차에 따라 이의를 신청할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑤ 본 조 제4항의 이의가 정당하다고 회사가 인정하면, 회사는 즉시 본 서비스의 이용을 재개하며, 본 서비스나 콘텐츠에 특정한 이용기간이 있는 등 필요한 때에는 동일한 서비스나 콘텐츠 등을 다시 제공합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑥ 환급과 관련하여 회원이 본 조 제1항에 해당하면 회사는 회원의 계약 해지 의사표시에 대하여 회신한 날로부터, 본 조 제2항에 해당하면 회원에게 해지의 의사표시를 한 날로부터 3영업일 이내에 회원의 대금 결제와 동일한 방법으로 환급하고,
    동일한 방법으로 환급할 수 없을 때에는 대안을 제시하고 구매회원의 선택에 따라 과오금을 환급합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑦ 본 조에 따라 계약을 해지한 후 환급할 때, 회사는 회원이 앱스토어결제를 통한 서비스를 이용하면서 얻은 이익과 환불수수료(전체 대금의 10% 이내의 금액) 등에 해당하는 금액을 공제하고 환급할 수 있습니다.`,
  },

  //rule 23
  {
    type: elementType.strongText,
    text: `제23조 (양도 금지)`,
  },
  {
    type: elementType.paragraph,
    text: `회원은 서비스의 이용 권한과, 기타 이용 계약상 지위를 타인에게 양도하거나 증여할 수 없으며, 담보로 제공할 수 없습니다.`,
  },
  {
    type: elementType.breakLine
  },

  //section 6
  {
    type: elementType.subtitle,
    text: `제6장 손해배상 등`,
  },

  //rule 24
  {
    type: elementType.strongText,
    text: `제24조 (손해배상)`,
  },
  {
    type: elementType.paragraph,
    text: `① 본 약관의 규정을 위반하여 회사에 손해를 끼친 회원은 회사에 발생한 통상의 손해를 배상할 책임이 있으며, 특별한 사정으로 손해가 발생했을 때 회원이 그 사정을 알았거나 알 수 있었다면 회원은 그에 대해 배상할 책임이 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회원이 본 서비스를 이용하면서 불법행위를 하거나 본 약관을 위반하는 행위를 하여 회사가 해당 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는다면 회사는 우선 이의를 제기한 제3자에게 대응하며, 이 과정에서 회사에 비용과 손해가 발생한다면 회사는 그것과 관련하여 해당 회원에게 구상권을 행사합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사가 본 서비스를 통하여 직접 통신판매업자로서 판매한 상품의 하자 등으로 회원에게 손해가 발생했을 때 회사는 이를 배상하며, 그 방법과 절차는 전자상거래 등에서의 소비자보호에 관한 법률, 콘텐츠산업진흥법 등 관련 법령, 고시 등을 따릅니다.`,
  },

  //rule 25
  {
    type: elementType.strongText,
    text: `제25조 (면책사항)`,
  },
  {
    type: elementType.paragraph,
    text: `① 회사는 천재지변 또는 이에 준하는 불가항력으로 본 서비스를 제공할 수 없는 때에는 본 서비스 제공에 대한 책임이 면제됩니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사는 회원의 귀책사유에 기인한 본 서비스의 이용장애에 대해 책임을 지지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 회사는 고의 또는 과실이 없는 한 본 서비스를 이용한 회원이 기대하는 수익을 잃은 것에 대해 책임을 지지 않으며, 그 밖에 본 서비스를 통하여 얻은 자료로 인한 손해 등에 대해서도 책임을 지지 않습니다. 회사는 회원이 홈페이지에 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등의 내용에 대해 책임을 지지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `④ 본 서비스를 매개로 회원 상호 간 또는 회원과 제3자 간에 분쟁이 발생했을 때 회사는 그것에 개입할 의무가 없으며, 고의 또는 과실이 없는 한 분쟁으로 인한 손해를 배상할 책임이 없습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑤ 회원이 자신의 개인정보를 타인에게 유출하거나 제공하여 발생한 피해에 대해 회사는 고의 또는 과실이 없는 한 일절 책임을 지지 않습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `⑥ 상품 또는 정보 등을 통해 표현하거나 드러나는 회원의 견해와 회사의 견해는 무관하며, 회사는 고의 또는 과실이 없는 한 회원이 제공하는 상품 또는 정보 등에 대하여 어떠한 책임도 부담하지 않습니다.`,
  },

  //rule 26
  {
    type: elementType.strongText,
    text: `제26조 (분쟁의 해결, 관할법원)`,
  },
  {
    type: elementType.paragraph,
    text: `① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생하면, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.`,
  },
  {
    type: elementType.paragraph,
    text: `② 회사와 회원 사이에 분쟁이 발생했을 때 회사 또는 회원은 전자상거래 등에서의 소비자 보호 등에 관한 법률, 콘텐츠산업진흥법 등 관련 법령에서 정하는 분쟁 조정 기구 등에 분쟁조정을 신청할 수 있습니다.`,
  },
  {
    type: elementType.paragraph,
    text: `③ 본 조 제1항, 제2항의 협의에서도 분쟁이 해결되지 않아 소송이 제기되었을 때, 관할법원은 민사소송법 등 관련 법령에 따른 관할법원을 1심 관할법원으로 정합니다.`,
  },
  {
    type: elementType.breakLine
  },

  //section end
  {
    type: elementType.subtitle,
    text: `부칙`,
  },
  {
    type: elementType.italyParagraph,
    text: `(시행일) 본 약관은 2023년 09월 15일부터 시행됩니다.`,
  },
  {
    type: elementType.breakLine
  },
]
