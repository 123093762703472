export const VALIDATION_MESSAGE = {
  REQUIRED: '필수',
  INVALID_EMAIL: '잘못된 이메일 주소',
  PASSWORD_NOT_MATCH: '비밀번호 확인이 일치하지 않습니다',
  ONLY_HAVE_LETTERS_NUMBERS: '텍스트에는 공백이나 특수 문자 없이 문자와 숫자만 포함되어야 합니다.',
  IMAGE_ONLY: 'Image only',
  FILE_TOO_BIG: 'File size too big, please select smaller file',
}

export const validateMinLength = (minLength: number) => {
  return `${minLength}자 이상 작성해 주세요`
}

export const validateMaxLength = (maxLength: number) => {
  return `${maxLength}자를 초과할 수 없습니다`
}

export const validateExactLength = (maxLength: number) => {
  return `정확히 ${maxLength}자 필요`
}
