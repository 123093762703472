import { OptionConfig, PopupHandle } from '../../components/PopUpCustom'

export class PopupHelper {
  static popup: PopupHandle | null

  static setDropDown(popup: PopupHandle | null) {
    if (popup) {
      this.popup = popup
    }
  }

  static showPopup(arg0: OptionConfig) {
    if (this.popup) {
      this.popup.openModal(arg0)
    }
  }

  static closePopup() {
    if (this.popup) {
      this.popup.closeModal()
    }
  }
}
