import { put, call } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { ChangePasswordData, LoginData, SignUpData } from '../model'
import { changePasswordApi, loginApi, logoutApi, signUpApi } from '../services/auth'
import { TYPES } from '../redux/types'
import { clearLocalStorage, setTokenLocalStorage } from '../utils'
import { PAGE_PATH } from '../constant/pages'
import { ACCOUNT_MESSAGE, AUTH_MESSAGE } from '../lang/message'
import { getProfileApi } from '../services/account'
import { ToastHelper } from '../utils/helpers/ToastHelper'

export function* loginSaga(action: any): any {
  const dataLogin: LoginData = action.payload
  const { email, password, isRememberPassword } = dataLogin
  try {
    const responseData = yield call(loginApi, { email, password })
    if (responseData?.data) {
      const { token, profile } = responseData?.data
      yield call(setTokenLocalStorage, token, isRememberPassword ? 30 : 3)
      yield put({ type: TYPES.SET_USER_INFO, payload: profile })
      yield put({ type: TYPES.SET_ACCESS_TOKEN, payload: { token: token } })
      yield put({ type: TYPES.CLEAR_SEARCH_INPUT })
      yield put(replace(`/${PAGE_PATH.HOME}`))
      ToastHelper.showMessage(
        AUTH_MESSAGE.TITLE_LOGIN_SUCCESS,
        AUTH_MESSAGE.LOGIN_SUCCESS,
        'success'
      )
    } else throw new Error(AUTH_MESSAGE.LOGIN_FAIL)
  } catch (error: any) {
    ToastHelper.showMessage(AUTH_MESSAGE.TITLE_LOGIN_FAIL, AUTH_MESSAGE.LOGIN_FAIL, 'error')
  }
}

export function* signUpSaga(action: any): any {
  const dataLogin: SignUpData = action.payload
  const { email, password, nickname } = dataLogin
  try {
    const responseData = yield call(signUpApi, { email, password, nickname })
    if (responseData?.data) {
      yield put(replace(`/${PAGE_PATH.LOGIN}`))
      ToastHelper.showMessage(
        AUTH_MESSAGE.TITLE_SIGN_UP_SUCCESS,
        AUTH_MESSAGE.SIGN_UP_SUCCESS,
        'success'
      )
    } else throw new Error(AUTH_MESSAGE.SIGN_UP_FAIL)
  } catch (error: any) {
    const messageError = { ...error }?.response?.data?.message
    ToastHelper.showMessage(
      AUTH_MESSAGE.TITLE_SIGN_UP_FAIL,
      messageError ?? AUTH_MESSAGE.SIGN_UP_FAIL,
      'error'
    )
  }
}

export function* getUserInfoSaga(): any {
  try {
    const responseData = yield call(getProfileApi)
    if (responseData?.data) {
      yield put({ type: TYPES.SET_USER_INFO, payload: responseData?.data })
    } else throw new Error(AUTH_MESSAGE.GET_PROFILE_FAIL)
  } catch (error: any) {
    const messageError = { ...error }?.response?.data?.message
    ToastHelper.showMessage(
      ACCOUNT_MESSAGE.TITLE_GET_PROFILE_FAIL,
      messageError ?? ACCOUNT_MESSAGE.GET_PROFILE_FAIL,
      'error'
    )
  }
}

export function* changePasswordSaga(action: any): any {
  try {
    const dataLogin: ChangePasswordData = action.payload
    const { oldPassword, password } = dataLogin
    yield call(changePasswordApi, {
      old_password: oldPassword,
      new_password: password,
    })
    yield put({ type: TYPES.SAGA_LOGOUT })
    yield put(replace(`/${PAGE_PATH.LOGIN}`))
    ToastHelper.showMessage(
      AUTH_MESSAGE.TITLE_CHANGE_PASSWORD_SUCCESS,
      AUTH_MESSAGE.CHANGE_PASSWORD_SUCCESS,
      'success'
    )
  } catch (error: any) {
    const messageError = { ...error }?.response?.data?.message
    ToastHelper.showMessage(
      AUTH_MESSAGE.TITLE_CHANGE_PASSWORD_FAIL,
      messageError ?? AUTH_MESSAGE.CHANGE_PASSWORD_FAIL,
      'error'
    )
  }
}

export function* logoutSaga() {
  try {
    yield call(logoutApi)
    // yield ToastHelper.showMessage(
    //   AUTH_MESSAGE.TITLE_LOGOUT_SUCCESS,
    //   AUTH_MESSAGE.LOGOUT_SUCCESS,
    //   'info'
    // )
  } catch (e) {
    console.log(e)
  } finally {
    yield forceLogout()
  }
}

export function* forceLogout() {
  yield put({ type: TYPES.CLEAR_ALL_USER_INFO })
  yield call(clearLocalStorage)
  yield put({ type: TYPES.CLEAR_SEARCH_INPUT })
  yield put(replace(`/${PAGE_PATH.HOME}`))
}
