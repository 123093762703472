import axios, { AxiosRequestConfig } from 'axios'
import { getTokenFromLocalStorage } from '../../utils'
import { HttpStatusCode } from './types'
import { store } from '../..'
import { TYPES } from '../../redux/types'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  responseType: 'json',
  timeout: 10000,
})

const apiClientDownload = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
})

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const statusCode = error?.response ? error?.response?.status : null

    if (statusCode === HttpStatusCode.FORBIDDEN) {
      store.dispatch({ type: TYPES.SAGA_FORCE_LOGOUT })
    }

    return Promise.reject(error)
  }
)

const defaultHeaders = {
  'Content-Type': 'application/json',
}

const defaultConfig = async (): Promise<AxiosRequestConfig> => {
  // tslint:disable-next-line: no-shadowed-variable
  const defaultConfig: AxiosRequestConfig = {
    headers: { ...defaultHeaders },
  }
  const token = getTokenFromLocalStorage()
  if (token) {
    defaultConfig.headers['Authorization'] = `JWT ${token}`
  }
  return defaultConfig
}

/**
 *
 * @param config
 */
const mergeConfig = async (config?: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const _defaultConfig = await defaultConfig()
  let headers = _defaultConfig.headers
  if (config && config.headers) {
    headers = { ...headers, ...config.headers }
  }
  return { ..._defaultConfig, ...config, headers }
}

const requestErrorHandler = (err?: any, skipAlert = false): Promise<any> => {
  return Promise.reject(err)
}

export async function get(url: string, config?: AxiosRequestConfig): Promise<any> {
  const _config = await mergeConfig(config)
  return apiClient
    .get(`${url}`, _config)
    .then((res) => res?.data)
    .catch(requestErrorHandler)
}

export async function post(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
  const _config = await mergeConfig(config)
  return apiClient
    .post(`${url}`, data, _config)
    .then((res) => res?.data)
    .catch(requestErrorHandler)
}

export async function put(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
  const _config = await mergeConfig(config)
  return apiClient
    .put(`${url}`, data, _config)
    .then((res) => res?.data)
    .catch(requestErrorHandler)
}

export async function patch(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
  const _config = await mergeConfig(config)
  return apiClient
    .patch(`${url}`, data, _config)
    .then((res) => res?.data)
    .catch(requestErrorHandler)
}

export async function deleteRequest(url: string, config?: AxiosRequestConfig): Promise<any> {
  const data = config && config.data ? config.data : ''
  const _config = await mergeConfig({ ...config, data })
  return apiClient
    .delete(`${url}`, _config)
    .then((res) => res?.data)
    .catch(requestErrorHandler)
}

/** GET request dedicated to image acquisition */
export async function downloadFile(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  const _config = await mergeConfig({
    ...config,
    data,
    responseType: 'blob',
  })
  return apiClientDownload
    .post(`${url}`, data, _config)
    .then((res) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'file.zip') // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
    .catch((err) => requestErrorHandler(err, true))
}

export const createUrl = (prefix: string, url = '') => {
  return `${prefix}${url && url + '/'}`
}
