import { applyMiddleware, createStore, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './root-reducer'
import rootSaga from '../sagas'

export const history = createBrowserHistory()

function configureStore(preloadedState?: any) {
  // Create middleware
  const sagaMiddleware = createSagaMiddleware()

  // Create Store
  const store = createStore(
    rootReducer(history),
    preloadedState,
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
  ) as any
  // Run middleware
  store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
