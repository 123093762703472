import { createUrl, get, patch, deleteRequest } from './api/api'

const accountPrefix = '/account/v1/'

export async function getProfileApi() {
  return get(createUrl(accountPrefix, 'profile'))
}

export async function uploadProfile(data: any) {
  return patch(createUrl(accountPrefix, 'profile/update'), data)
}

export async function deleteAccountApi() {
  return deleteRequest(createUrl(accountPrefix, 'delete-account'))
}
