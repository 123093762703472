export const BACKGROUND_COLOR = {
  BODY: '#fbfcff',
  PRIMARY: '#0dd6b8',
  SECONDARY: '#a09e9e',
  SUCCESS: '#49f0d3',
  WARNING: '#ffba68',
  DANGER: '#ff9b8a',
  INFO: '#d592ff',
  DARK: '#3f414d',
  LIGHT: '#fafafb',
  GRAY: '#b1b1b1',
}

export const TEXT_COLOR = {
  TEXT_TITLE: '#11141b',
  TEXT_BODY: '#777D74',
  TEXT_SUCCESS: '#49f0d3',
  TEXT_WARNING: '#ffba68',
  TEXT_DANGER: '#FF3838',
  TEXT_INFO: '#d592ff',
  TEXT_DARK: '#3f414d',
  TEXT_LIGHT: '#fafafb',
  TEXT_HIGHLIGHT: '0072BC',
  TEXT_NOTE: 'FB1F4C',
}

export const PROGRESS_COLOR = [
  BACKGROUND_COLOR.DANGER,
  BACKGROUND_COLOR.PRIMARY,
  BACKGROUND_COLOR.SECONDARY,
  BACKGROUND_COLOR.SUCCESS,
  BACKGROUND_COLOR.WARNING,
  BACKGROUND_COLOR.INFO,
  BACKGROUND_COLOR.GRAY,
  BACKGROUND_COLOR.DARK,
]
