import { UserData } from '../../model'
import { TYPES } from '../types'

export const INITIAL_STATE: { token: string; userInfo: UserData } = {
  token: '',
  userInfo: {
    id: 0,
    nickname: '',
    point: 0,
    used_point: 0,
    avatar: '',
    avatar_thumb: '',
  },
}

const userInfoReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TYPES.SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.payload,
      }
    }

    case TYPES.SET_ACCESS_TOKEN: {
      const { token } = action.payload
      return {
        ...state,
        token: token,
      }
    }

    case TYPES.CLEAR_ALL_USER_INFO:
      return INITIAL_STATE

    default:
      return state
  }
}

export default userInfoReducer
