//@ts-ignore
import CryptoJS from 'crypto-js'

export class CryptoService {
  static key_str = process.env.REACT_APP_ENCRYPT_KEY ?? ''
  static iv_str = this.key_str.substr(0, 16)

  static encrypt(plain_text: string) {
    if(!plain_text){
      return null
    }

    const key = CryptoJS.enc.Utf8.parse(this.key_str)
    const iv = CryptoJS.enc.Utf8.parse(this.iv_str)
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plain_text),
      key,
      {
        keySize: 16,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    )

    return encrypted.toString()
  }

  static decrypt(encrypted: string) {
    if(!encrypted){
      return null
    }

    const key = CryptoJS.enc.Utf8.parse(this.key_str)
    const iv = CryptoJS.enc.Utf8.parse(this.iv_str)

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })

    return decrypted.toString(CryptoJS.enc.Utf8)
  }
}
