import React from 'react'
import { Provider } from 'react-redux'
import { Routes } from './router/Routes'
import { LoadingPage } from './layouts/LoadingPage'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PopUpCustom from './components/PopUpCustom'
import { PopupHelper } from './utils/helpers/PopupHelper'
import PopUpCode from './components/PopUpCode'
import { PopUpCodeHelper } from './utils/helpers/PopUpCodeHelper'

function App({ store, history }: any) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <>
          <React.Suspense fallback={<LoadingPage />}>
            <Routes />
          </React.Suspense>
          <ToastContainer
            autoClose={3000}
            limit={3}
            draggablePercent={60}
            hideProgressBar={true}
            pauseOnHover={false}
            closeButton={false}
          />
          <PopUpCustom
            ref={(ref) => {
              PopupHelper.setDropDown(ref)
            }}
          />
          <PopUpCode
            ref={(ref) => {
              PopUpCodeHelper.setDropDown(ref)
            }}
          />
        </>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
