import React, { useImperativeHandle, useState, ForwardRefRenderFunction, forwardRef } from 'react'
import './index.css'
import { useDispatch } from 'react-redux'
import { TYPES } from '../../redux/types'
import ReceiptContent from './ReceiptContent'
import VerifyCodeContent from './VerifyCodeContent'

export type PopupHandle = {
  openModal: (arg0: string | number, arg1: number, arg2?: boolean) => void
  closeModal: () => void
}

const contentType = {
  verifyCode: 'verifyCode',
  receipt: 'receipt',
}

const PopUpCode: ForwardRefRenderFunction<PopupHandle, any> = (props, ref) => {
  const dispatch = useDispatch()

  const [visible, setVisible] = useState<boolean>(false)

  const [bookId, setBookId] = useState<number | string>(-1)
  const [contentTypeCurrent, setContentTypeCurrent] = useState<string>(contentType.verifyCode)
  const [bookPrice, setBookPrice] = useState<number>(0)
  const [textCode, setTextCode] = useState('')

  const openModal = (bookId: string | number, price: number, isSkipVerifyCode = false) => {
    dispatch({ type: TYPES.SAGA_GET_PROFILE })
    setBookId(bookId)
    setBookPrice(price)
    if (isSkipVerifyCode) {
      setContentTypeCurrent(contentType.receipt)
    }
    setVisible(true)
  }

  const closeModal = () => {
    setBookId(-1)
    setBookPrice(0)
    setContentTypeCurrent(contentType.verifyCode)
    setTextCode('')
    setVisible(false)
  }

  useImperativeHandle(
    ref,
    () => ({
      openModal: openModal,
      closeModal: closeModal,
    }),
    []
  )

  const renderContent = () => {
    const changeContent = () => {
      setContentTypeCurrent(contentType.receipt)
    }

    switch (contentTypeCurrent) {
      case contentType.verifyCode: {
        return (
          <VerifyCodeContent
            closeModal={closeModal}
            bookId={bookId}
            setBookPrice={setBookPrice}
            changeContent={changeContent}
            textCode={textCode}
            setTextCode={setTextCode}
          />
        )
      }
      case contentType.receipt: {
        return (
          <ReceiptContent
            closeModal={closeModal}
            bookPrice={bookPrice}
            bookId={bookId}
            textCode={textCode}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  if (!visible) {
    return null
  }

  return (
    <div className="popup-container">
      <div className="container p-0">
        <div className="row no-gutters height-self-center">
          <div className="col-sm-12 align-self-center page-content rounded">
            <div className="row m-0">
              <div className="col-sm-12 sign-in-page-data">{renderContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// PopUpCode.displayName = 'PopUpCode'

export default forwardRef(PopUpCode)
