export const AUTH_MESSAGE = {
  //login
  LOGIN_LOADING: 'Login to your account',
  TITLE_LOGIN_SUCCESS: '로그인 완료',
  LOGIN_SUCCESS: '로그인 되었습니다!',
  TITLE_LOGIN_FAIL: '계정 정보를 확인해주세요.',
  LOGIN_FAIL: '계정 정보가 올바르지 않은 것 같습니다. 계정 정보를 다시 한 번 확인해주십시오.',
  TITLE_LOGIN_TO_PERFORM_TASK: '로그인 해주세요',
  LOGIN_TO_PERFORM_TASK: '이 작업을 수행하려면 로그인하세요.',

  //logout
  TITLE_LOGOUT_SUCCESS: '로그 아웃',
  LOGOUT_SUCCESS: '로그아웃되었습니다',

  //sign_up
  TITLE_SIGN_UP_SUCCESS: '가입 완료',
  SIGN_UP_SUCCESS: '가입이 완료 되었습니다',
  TITLE_SIGN_UP_FAIL: '가입 실패',
  SIGN_UP_FAIL: '가입에 실패하였습니다',

  //change password
  TITLE_CHANGE_PASSWORD_SUCCESS: '비밀번호 변경 완료',
  CHANGE_PASSWORD_SUCCESS: '비밀번호가 성공적으로 변경되었습니다',
  TITLE_CHANGE_PASSWORD_FAIL: '비밀번호 변경 실패',
  CHANGE_PASSWORD_NOT_FIND_EMAIL: '이메일 주소를 찾을 수 없습니다',
  CHANGE_PASSWORD_FAIL: '비밀번호를 확인해주세요',

  //delete account
  TITLE_DELETE_ACCOUNT_SUCCESS: '계정이 삭제되었습니다.',
  DELETE_ACCOUNT_SUCCESS: '계정이 삭제되었습니다.',
  TITLE_DELETE_ACCOUNT_FAIL: '계정 삭제 실패.',
  DELETE_ACCOUNT_FAIL: '계정을 삭제하는 중에 문제가 발생했습니다.',

  GET_PROFILE_FAIL: 'Get profile info fail',
}

export const ACCOUNT_MESSAGE = {
  TITLE_GET_PROFILE_FAIL: '사용자 정보 가져오기 실패',
  GET_PROFILE_FAIL: '사용자 정보 데이터를 가져올 때 문제가 발생했습니다',
}

export const WISH_LIST_MESSAGE = {
  TITLE_ADD_WISH_LIST_SUCCESS: '위시리스트 추가 완료',
  ADD_WISH_LIST_SUCCESS: '책이 성공적으로 추가되었습니다',
  TITLE_ADD_WISH_LIST_FAIL: '위시리스트에 추가하지 못했습니다.',
  ADD_WISH_LIST_FAIL: '그 책은 이미 당신의 위시 리스트에 있습니다.',
  TITLE_DELETE_WISH_LIST: '위시리스트 삭제',
  DELETE_WISH_LIST_MESSAGE: '위시 리스트에서 해당 E-BOOK을 삭제하시겠습니까?',
}

export const PURCHASE_BOOK_MESSAGE = {
  NOT_ENOUGH_POINT_TITLE: '포인트가 부족합니다.',
  NOT_ENOUGH_POINT_MESSAGE: '포인트 충전 후 구매해주세요.',
  NOT_ENOUGH_POINT_BUTTON: '포인트 구매하기',
  USED_SERIAL_CODE: '이미 사용된 코드번호입니다.',
  WRONG_SERIAL_CODE: '인증 코드가 일치하지 않습니다. 코드를 다시 확인 해 주세요.',
  PURCHASE_SUCCESS_TITLE: '구매 완료!',
  PURCHASE_SUCCESS_MESSAGE: '포인트를 사용하여 구매를 완료하였습니다.',
  PURCHASE_SUCCESS_BUTTON_CHECK_POINT_HISTORY: '구매내역 확인하기',
  PURCHASE_SUCCESS_BUTTON_CLOSE: '장바구니로 돌아가기',
  PURCHASE_FAIL_TITLE: '도서 구매 시 오류 발생!',
  PURCHASE_FAIL_MESSAGE: '도서 구매 과정에서 오류가 발생했습니다. 다시 시도해 주세요',
}
