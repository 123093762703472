import moment from 'moment'
import { DATE_FORMAT, ParamScreen } from '../constant'
import { ImageItem } from '../model'
import { CryptoService } from './helpers/CryptoService'
import { store } from '..'
import { TYPES } from '../redux/types'
// import sanitizeHtml from 'sanitize-html'
/**
 * Returns x raised to the n-th power.
 *
 * @param {Location} location for rerender when has auth.
 */

const TOKEN_KEY = 'token'

export const isPhoneDevice: boolean = window.navigator.userAgent.toLowerCase() === 'g-school'

export function setTokenLocalStorage(token: string, expirationDays: number) {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + expirationDays)

  localStorage.setItem(
    TOKEN_KEY,
    CryptoService.encrypt(JSON.stringify({ token, expiredDate: expirationDate.getTime() }))
  )
}

export function getTokenFromLocalStorage() {
  const tokenDataEncrypted = localStorage.getItem(TOKEN_KEY)

  if (!tokenDataEncrypted) {
    return null
  }

  try {
    const tokenData = JSON.parse(CryptoService.decrypt(tokenDataEncrypted))
    const { token, expiredDate } = tokenData
    const currentDate = new Date()

    if (Number(currentDate.getTime()) > Number(expiredDate)) {
      localStorage.clear()
      store.dispatch({ type: TYPES.CLEAR_ALL_USER_INFO })
      return null
    }

    if (token) {
      return token
    }

    return null
  } catch (err) {
    return null
  }
}

export function clearLocalStorage() {
  localStorage.clear()
}

export const generateParamPayload = (payload: any) => {
  const paramsPayloadArray = []
  for (const keyPayload in payload) {
    if (
      payload.hasOwnProperty(keyPayload) &&
      payload[keyPayload] !== undefined &&
      payload[keyPayload] !== null &&
      payload[keyPayload] !== ''
    ) {
      paramsPayloadArray.push(`${keyPayload}=${payload[keyPayload]}`)
    }
  }
  return `?${paramsPayloadArray.join('&')}`
}

export const convertNumberToMoneyType = (number: number) => {
  if (typeof number !== 'number') {
    return 0
  }

  const digits = String(number).split('')
  const separatedDigits = []

  while (digits.length > 0) {
    separatedDigits.unshift(digits.splice(-3).join(''))
  }

  return separatedDigits.join(',')
}

export const getDateFromString = (dateString: string, originFormat: string, wishFormat: string) => {
  return moment(dateString, originFormat).format(wishFormat)
}

export const getMultipleLineString = (text: string) => {
  if (!text) {
    return ''
  }
  const paragraphArray = text.split('\n')
  return paragraphArray.map((item: string, index: number) => {
    return (
      <>
        {item}
        {index !== paragraphArray.length - 1 && <br />}
      </>
    )
  })
}

export const getRandomString = () => {
  return Math.random().toString(36).slice(2, 10)
}

export const getFullInfoOfBook = (
  author: string,
  publishing_company: string,
  publication_date: string,
  classification?: string,
  total_page?: number
) => {
  const lineDataArray: Array<string | number> = []
  if (author) {
    if (classification) {
      author = `${author}, ${classification}`
    }
    lineDataArray.push(author)
  }
  if (publishing_company) {
    lineDataArray.push(publishing_company)
  }
  if (publication_date) {
    lineDataArray.push(
      getDateFromString(publication_date, DATE_FORMAT.ONLY_DATE, DATE_FORMAT.ONLY_DATE_DOT)
    )
  }
  if (total_page) {
    lineDataArray.push(`${total_page}페이지`)
  }
  return lineDataArray.join(' /')
}

export const getFeatureImagePathBook = (images: Array<ImageItem>): string => {
  if (!Array.isArray(images) || images.length === 0) {
    return 'images/books/preventiveBook.jpg'
  }

  const imageFeature = images.find((image) => image.is_feature)

  return imageFeature ? imageFeature.image : images[0].image
}

export const createFileNameFollowUri = (uri: string) => {
  //eslint-disable-next-line
  return uri.replace(/^.*[\\\/]/, '').split('?')[0]
}

export const makeMarkedSerialCode = (code: string): string => {
  if (!code) return ''

  let trimCode = code.replace(/[^a-zA-Z0-9]/g, '')
  trimCode = trimCode.toUpperCase()
  trimCode = code.slice(0, 10)

  if (!trimCode) return ''

  const resultArray: Array<string> = []

  const charArray = trimCode.split('')

  const firstGroup = charArray.splice(0, 4)

  resultArray.push(firstGroup.join(''))

  if (charArray.length > 0) {
    const secondGroup = charArray.splice(0, 2)
    resultArray.push(secondGroup.join(''))
  }

  if (charArray.length > 0) {
    const thirdGroup = charArray.splice(0, 4)
    resultArray.push(thirdGroup.join(''))
  }

  return resultArray.join('-')
}

export const getParamInfo = (param: string, field: string): string | null => {
  const searchParams = new URLSearchParams(param)
  return searchParams.get(field)
}

export const getPageDefaultFromParams = (param: string): number => {
  const pageParam = getParamInfo(param, ParamScreen.page)
  return pageParam && Number(pageParam) > 0 ? Number(pageParam) : 1
}
