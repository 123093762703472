import React from 'react'
import { TEXT_COLOR } from '../../constant/colors'

export type MessageType = 'success' | 'warning' | 'error' | 'info'

type ToastMessageProps = {
  title: string
  message: string
  type?: MessageType
}

const ToastMessage: React.FC<ToastMessageProps> = ({ title, message, type = 'info' }) => {
  const getColorText = () => {
    switch (type) {
      case 'success': {
        return TEXT_COLOR.TEXT_SUCCESS
      }
      case 'warning': {
        return TEXT_COLOR.TEXT_WARNING
      }
      case 'error': {
        return TEXT_COLOR.TEXT_DANGER
      }
      case 'info': {
        return TEXT_COLOR.TEXT_INFO
      }
      default: {
        return TEXT_COLOR.TEXT_INFO
      }
    }
  }

  return (
    <>
      {title && (
        <h6 className="font-weight-bold" style={{ color: getColorText() }}>
          {title}
        </h6>
      )}
      {message && <div className="font-size-9 text-black">{message}</div>}
    </>
  )
}

export default ToastMessage
