import { combineReducers } from 'redux'
import { History } from 'history'
import { connectRouter } from 'connected-react-router'
import userInfoReducer from './reducer/userInfo'
import topBarReducer from './reducer/topBar'
import sideBarReducer from './reducer/sideBar'

const rootReducer = (history: History) =>
  combineReducers({
    userInfo: userInfoReducer,
    topBar: topBarReducer,
    sideBar: sideBarReducer,
    router: connectRouter(history),
  })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
