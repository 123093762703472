import React from 'react'
import './index.css'
import { elementType, privacyStatement, purchaseTerm } from './PrivacyText'

type TermAndPrivacyProps = {
  type: 'PrivacyStatement' | 'PurchaseTerm'
}

const TermAndPrivacy: React.FC<TermAndPrivacyProps> = ({ type }) => {
  const renderText = () => {
    const dataRender = type === 'PrivacyStatement' ? privacyStatement : purchaseTerm
    return dataRender.map((item, index) => {
      const { type, text, arrayText } = item
      switch (type) {
        case elementType.title: {
          return (
            <h3
              className="text-center font-size-25 mb-4 text-primary"
              key={`privacy-term-${index}`}
            >
              {text}
            </h3>
          )
        }
        case elementType.paragraph: {
          return (
            <p className="mb-2" key={`privacy-term-${index}`}>
              {text}
            </p>
          )
        }
        case elementType.subtitle: {
          return (
            <h5 className="font-size-22 font-weight-bold" key={`privacy-term-${index}`}>
              {text}
            </h5>
          )
        }
        case elementType.strongText: {
          return (
            <h6 className="font-size-18 mt-3 mb-1" key={`privacy-term-${index}`}>
              {text}
            </h6>
          )
        }
        case elementType.list: {
          return (
            <ul key={`privacy-term-${index}`} className="term-and-privacy-list">
              {arrayText?.map((textList, indexList) => {
                return <li key={`list-item-${indexList}`}>{textList}</li>
              })}
            </ul>
          )
        }
        case elementType.listWithMarkNumber: {
          return (
            <ul key={`privacy-term-${index}`} className="term-and-privacy-without-number">
              {arrayText?.map((textList, indexList) => {
                return (
                  <li key={`list-item-${indexList}`}>
                    <span className="font-weight-bold">{`${indexList + 1}.`}</span>
                    {textList}
                  </li>
                )
              })}
            </ul>
          )
        }
        case elementType.listWithNoMark: {
          return (
            <ul key={`privacy-term-${index}`} className="term-and-privacy-without-mark">
              {arrayText?.map((textList, indexList) => {
                return <li key={`list-item-${indexList}`}>{textList}</li>
              })}
            </ul>
          )
        }
        case elementType.italyParagraph: {
          return (
            <p className="font-italic mb-2" key={`privacy-term-${index}`}>
              {text}
            </p>
          )
        }
        case elementType.note: {
          return (
            <p className="font-italic mb-2 font-weight-bold" key={`privacy-term-${index}`}>
              {text}
            </p>
          )
        }
        case elementType.breakLine: {
          return <br />
        }
        default: {
          return null
        }
      }
    })
  }

  return (
    <div className="container d-flex justify-content-center">
      <div className="term-and-privacy-container bg-white rounded border-primary">
        <div className="row w-25 mb-4">
          <img className="w-100" src="./images/logo-full.png" />
        </div>
        <div className="w-100 mt-2">{renderText()}</div>
      </div>
    </div>
  )
}

export default TermAndPrivacy
