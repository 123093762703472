import React, {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useState,
  ForwardRefRenderFunction,
} from 'react'
import './index.css'

export type PopupHandle = {
  openModal: (arg0: OptionConfig) => void
  closeModal: () => void
  setIsSubmitting: (arg0: boolean) => void
}

export type OptionConfig = {
  visibleIcon?: boolean
  title: string
  message: ReactNode | string
  messageData?: ReactNode
  dataInfo?: ReactNode | null
  buttonTitle?: string
  buttonCallback?: () => void
  buttonHandelError?: (err: any) => void
  buttonHandelFinally?: () => void
  buttonTitleSecond?: string
}

const originConfig: OptionConfig = {
  visibleIcon: false,
  title: '',
  message: '',
  dataInfo: null,
  buttonTitle: '',
  buttonCallback: () => {},
  buttonHandelError: () => {},
  buttonHandelFinally: undefined,
  buttonTitleSecond: '',
}

const PopUpCustom: ForwardRefRenderFunction<PopupHandle, any> = (props, ref) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [config, setConfig] = useState<OptionConfig>(originConfig)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const openModal = (config: OptionConfig) => {
    setConfig(config)
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
    setConfig(originConfig)
    setIsSubmitting(false)
  }

  useImperativeHandle(
    ref,
    () => ({
      openModal: openModal,
      closeModal: closeModal,
      setIsSubmitting: setIsSubmitting,
    }),
    []
  )

  const {
    visibleIcon,
    title,
    message,
    dataInfo,
    buttonTitle,
    buttonCallback,
    buttonHandelError,
    buttonHandelFinally,
    buttonTitleSecond,
    messageData,
  } = config

  const onClickButtonTitle = async () => {
    if (!buttonCallback) {
      return
    }
    try {
      setIsSubmitting(true)
      await buttonCallback()
    } catch (err: any) {
      if (buttonHandelError) {
        buttonHandelError(err)
      }
      console.log(err)
    } finally {
      setIsSubmitting(false)
      if (buttonHandelFinally) {
        buttonHandelFinally()
      }
      closeModal()
    }
  }

  if (!visible) {
    return null
  }

  return (
    <div className="popup-container">
      <div className="container p-0">
        <div className="row no-gutters height-self-center">
          <div className="col-sm-12 align-self-center page-content rounded">
            <div className="row m-0">
              <div className="col-sm-12 sign-in-page-data">
                <div className="sign-in-from bg-primary rounded">
                  {visibleIcon && (
                    <div className="m-0 text-center text-white mt-n3">
                      <i className="ri-error-warning-fill font-size-120 m-0 p-0"></i>
                    </div>
                  )}
                  <h3 className="mb-0 text-center text-white">
                    <strong>{title}</strong>
                  </h3>
                  <p className="text-center text-white mt-3">{message}</p>
                  {messageData ?? null}
                  {dataInfo}
                  <div className="mt-5">
                    {buttonTitle && buttonCallback && (
                      <button
                        className="btn btn-white d-block w-100"
                        onClick={onClickButtonTitle}
                        disabled={isSubmitting}
                      >
                        {buttonTitle}
                      </button>
                    )}
                    <button
                      className={`btn d-block w-100 mt-1 ${
                        buttonTitle && buttonCallback ? 'btn-primary' : 'btn-white'
                      }`}
                      onClick={closeModal}
                      disabled={isSubmitting}
                    >
                      {buttonTitleSecond ? buttonTitleSecond : '취소'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(PopUpCustom)
