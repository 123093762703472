export const TYPES = {
  // saga area
  SAGA_LOGIN: 'SAGA_LOGIN',
  SAGA_LOGOUT: 'SAGA_LOGOUT',
  SAGA_FORCE_LOGOUT: 'SAGA_FORCE_LOGOUT',
  SAGA_SIGN_UP: 'SAGA_SIGN_UP',
  SAGA_GET_PROFILE: 'SAGA_GET_PROFILE',
  SAGA_CHANGE_PASSWORD: 'SAGA_CHANGE_PASSWORD',

  //auth area
  SET_USER_INFO: 'SET_USER_INFO',
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  CLEAR_ALL_USER_INFO: 'CLEAR_ALL_USER_INFO',

  //topBar
  CHANGE_SEARCH_INPUT: 'CHANGE_SEARCH_INPUT',
  UPDATE_SEARCH_INPUT: 'UPDATE_SEARCH_INPUT',
  UPDATE_DONE_SEARCH_INPUT: 'UPDATE_DONE_SEARCH_INPUT',
  CLEAR_SEARCH_INPUT: 'CLEAR_SEARCH_INPUT',

  //sidebar
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
  CLEAR_SIDEBAR: 'CLEAR_SIDEBAR',
}
